import React, { useState, useEffect } from 'react';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { stringify } from '@bitauth/libauth';
import { toast } from 'react-toastify';
import ModalTask from '../../components/ModalTask';
import QRCode from 'react-qr-code';
import BCHLogo from '../../assets/img/bch.png';
import { environmentUrl } from '../../constants/environment';
import {
  LoansContainer,
  ContentWrapper,
  Title,
  TaskList,
  TaskCard,
  TaskContent,
  QRSection,
  TaskDetails,
  TaskTitle,
  DonationAmount,
  WeightBarContainer,
  WeightBar,
  Address,
  HeaderSection,
  AddTaskButton,
  TaskDescription,
  ModalContent,
  ModalTitle,
  Input,
  TextArea,
  ModalButtons,
  CancelButton,
  AddButton,
  PaymentInstructions,
  QRCodeWrapper,
  AddressWrapper,
  StyledBCHLogo,
  InputWrapper,
  CharCount,
  AddUserButton,
  UserModalContent,
  XPubInput,
  DeleteButton,
  StyledSelect,
  SelectWrapper,
  MinimizedContent,
  ExpandedContent,
  MinimizeWrapper,
  ExpandedWrapper,
  ExpandedWrapperColumn,
  StyledBackground,
  TaskBackground,
  InfoSection,
  InfoContent,
  InfoHeader,
  TotalDonations,
  TotalDonatedRow,
  TabContainer,
  TabButton,
} from './Tasks.styles';

interface Task {
  index: string;
  title: string;
  description: string;
  address: string;
  currentDonations: bigint;
  totalDonations: bigint;
  createdAt: Date;
}

// Add these new interfaces
interface ToastMessage {
  id: string;
  content: React.ReactNode;
  timestamp: Date;
}

type TaskType = 'active' | 'old';

const Tasks: React.FC = () => {
    const { walletConnectInstance, walletConnectSession, connectedChain } = useBlockchainContext();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [tasks, setTasks] = useState<Task[]>([]);
    const [totalDonations, setTotalDonations] = useState<bigint>(BigInt(0));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [pendingTask, setPendingTask] = useState<Task | null>(null);
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const MAX_TITLE_LENGTH = 100;
    const MAX_DESCRIPTION_LENGTH = 500;
    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
    });
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [newUser, setNewUser] = useState({
        username: '',
        xpub: ''
    });
    const [users, setUsers] = useState<string[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>('');
    const [expandedCards, setExpandedCards] = useState<Set<string>>(new Set());
    //for dev environment detection
    const [serverUrl, setServerUrl] = useState<string>('');
    const [isInfoExpanded, setIsInfoExpanded] = useState(false);
    const [activeTab, setActiveTab] = useState<TaskType>('active');
    const [oldTasks, setOldTasks] = useState<Task[]>([]);

///////////////////////////////////////////////
//## UseEffects

  // Check if we're in the development environment
  useEffect(() => {
    if (environmentUrl == 'http://localhost:3002') {
      setServerUrl('http://localhost:3006');
    } else {
      setServerUrl(environmentUrl);
    }
  }, [environmentUrl]);

  // Load users on component mount
  useEffect(() => {
    if (serverUrl != '') {
      const fetchUsers = async () => {
          try {
            const response = await fetch(serverUrl + '/get-users');
            const data = await response.json();
            setUsers(data);
            if (data.length > 0) {
              setSelectedUser(data[0]); // Select first user by default
            }
          } catch (error) {
            console.error('Error fetching users:', error);
            toast.error('Failed to fetch users');
          }
      };

      fetchUsers();
    }
  }, [serverUrl]);

  // Load tasks when user is selected
  /*
  useEffect(() => {
    if (selectedUser) {
      const fetchUserTasks = async () => {
        try {
          const response = await fetch(serverUrl + `/get-user-tasks/${selectedUser}`);
          if (!response.ok) {
            throw new Error('Failed to fetch tasks');
          }
          const data = await response.json();
            setTasks(data.map((task: any) => ({
              ...task,
              currentDonations: BigInt(task.currentDonations),
              totalDonations: BigInt(task.totalDonations)
            })));
        } catch (error) {
            console.error('Error fetching user tasks:', error);
            toast.error('Failed to fetch tasks');
        }
      };

        fetchUserTasks();
    } else {
      setTasks([]); // Clear tasks if no user is selected
    }
  }, [selectedUser]);
*/
  useEffect(() => {
    if (selectedUser) {
        const fetchTasks = async () => {
            try {
                const endpoint = activeTab === 'active' 
                    ? `/get-user-tasks/${selectedUser}`
                    : `/get-user-old-tasks/${selectedUser}`;
                
                const response = await fetch(serverUrl + endpoint);
                if (!response.ok) {
                    throw new Error('Failed to fetch tasks');
                }
                const data = await response.json();
                const formattedTasks = data.map((task: any) => ({
                    ...task,
                    currentDonations: BigInt(task.currentDonations),
                    totalDonations: BigInt(task.totalDonations)
                }));

                if (activeTab === 'active') {
                    setTasks(formattedTasks);
                } else {
                    setOldTasks(formattedTasks);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                toast.error('Failed to fetch tasks');
            }
        };

        fetchTasks();
    } else {
        setTasks([]);
        setOldTasks([]);
    }
  }, [selectedUser, activeTab, serverUrl]);

///////////////////////////////////////////////
///// WebSocket connection setup
  useEffect(() => {
      let ws: WebSocket;
      try {
        if (serverUrl == 'https://fundme.cash') {
          ws = new WebSocket('wss://fundme.cash/ws-tasks');
        } else {
          ws = new WebSocket('ws://localhost:3006')
        }

        // Define WebSocket event handlers
        ws.onopen = () => {
            console.log('Connected to server');
        };

        ws.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.type === 'tasksUpdated' && message.data.username === selectedUser) {
              setTasks(message.data.tasks.map((task: any) => ({
                  ...task,
                  currentDonations: BigInt(task.currentDonations),
                  totalDonations: BigInt(task.totalDonations)
              })));
          }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = () => {
            console.log('Disconnected from server');
            // Optional: Implement reconnection logic here
        };

        setSocket(ws);

      } catch (error) {
        console.error('Error initializing WebSocket:', error);
      }

      // Cleanup on component unmount
      return () => {
          ws.close();
      };
//    }
  }, [serverUrl, selectedUser]);

///////////////////////////////////////////////
///// Calculate total donations
  useEffect(() => {
    const total = tasks.reduce((sum, task) => sum + task.currentDonations, BigInt(0));
    setTotalDonations(total);
  }, [tasks]);

///////////////////////////////////////////////
//////## Handle adding a new task
  const handleAddTask = async () => {
    if (!selectedUser) {
      toast.error('Please select a user first');
      return;
  }

    setIsProcessing(true);
    try {
      const response = await fetch(serverUrl + '/add-user-task', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: selectedUser,
            title: newTask.title,
            description: newTask.description
          }),
      });

      if (!response.ok) {
        console.log('add task response not.ok');
        const error = await response.json();
        throw new Error(error.error || 'Failed to create task');
      }

      const task = await response.json();

      // Close the modal and reset form
      setNewTask({ title: '', description: '' });
      setIsModalOpen(false);

      // Show payment instructions toast
      toast.info(
        <PaymentInstructions>
          <h3>Activate Your Task</h3>
          <p>Send $5 worth of BCH to activate your task:</p>
          <QRCodeWrapper>
            <QRCode 
              value={task.address}
              size={150}
              bgColor="white"
              fgColor="black"
              style={{ width: '100%', height: 'auto' }}
            />
          </QRCodeWrapper>
          <AddressWrapper onClick={() => copyToClipboard(task.address)}>
            {task.address}
          </AddressWrapper>
          <small>Click address to copy</small>
        </PaymentInstructions>,
        {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          closeButton: true
        }
      );
    } catch (error) {
        console.error('Error adding task:', error);
        toast.error('Failed to create task. ' + error);
    } finally {
        setIsProcessing(false);
        console.log('add task finished');
    }
  };

///////////////////////////////////////////////
//////## Handle adding a new user
  const handleAddUser = async () => {
    setIsProcessing(true);
    try {
        const response = await fetch(serverUrl + '/register-xpub', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newUser),
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.error || 'Failed to register user');
        }

        const result = await response.json();
        
        // Reset form and close modal
        setNewUser({ username: '', xpub: '' });
        setIsUserModalOpen(false);
        
        toast.success(`User ${result.username} registered successfully!`);
    } catch (error) {
        console.error('Error registering user:', error);
        toast.error('Failed to register user. ' + error);
    } finally {
        setIsProcessing(false);
    }
  };

//////## Handle deleting a task
const handleDeleteTask = async (taskIndex: number) => {
  if (!selectedUser) {
      toast.error('Please select a user first');
      return;
  }

  try {
      const response = await fetch(serverUrl + `/delete-user-task/${selectedUser}/${taskIndex}`, {
          method: 'DELETE',
      });

      if (!response.ok) {
          const error = await response.json();
          throw new Error(error.error || 'Failed to delete task');
      }

      toast.success('Task deleted successfully');
  } catch (error) {
      console.error('Error deleting task:', error);
      toast.error('Failed to delete task. ' + error);
  }
};

//////## Copy to clipboard
    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text);
      toast.success('Address copied to clipboard!');
    };

//////## Add toggle function for cards
const toggleCard = (taskIndex: string) => {
  setExpandedCards(prev => {
      const newSet = new Set(prev);
      if (newSet.has(taskIndex)) {
          newSet.delete(taskIndex);
      } else {
          newSet.add(taskIndex);
      }
      return newSet;
  });
};

//////## Sign Transaction via WalletConnect
    async function signTransaction(options: any) {
        console.log('signing transaction...');
        try {
            if (walletConnectInstance) {
            const params = JSON.parse(stringify(options));
            console.log('wc params:');
            console.log(params);
            setError('Requesting signature from your wallet...')
            const result = await walletConnectInstance.request({
                chainId: connectedChain,
                topic: walletConnectSession.topic,
                request: {
                method: "bch_signTransaction",
                params: params,
                },
            });
            return result;
            }
        } catch (error) {
            console.log('signTransation error: ' + error);
            return undefined;
        }
    }

  return (
    <LoansContainer>
      <StyledBackground />

      <ContentWrapper>
        <InfoSection>
          <InfoHeader onClick={() => setIsInfoExpanded(!isInfoExpanded)}>
              What is this? {isInfoExpanded ? '▼' : '▶'}
          </InfoHeader>
          <InfoContent $isExpanded={isInfoExpanded}>
              <p>
                This is a simple user tasks list with scoring based off BCH donations.
              </p>
              <ul>
                <li>Anyone can create a task on a users list, but you might want to verify the user will actually <i>do</i> the task.</li>
                <li>There are no refunds - the moment you send a donation it is given to and belongs to the user.</li>
              </ul>
              <p>
                  Here's how it works:
              </p>
              <ol>
                  <li>Create a user by clicking "Register User" and supply a name and xpub key (use a new wallet!)</li>
                  <li>Select the new user then click "Add Task" and supply a name and description</li>
                  <li>Pay 0.0001 BCH to create the task. This is the initial donation and spam prevention. (unpaid tasks are deleted after 30mins)</li>
                  <li>Tasks with more BCH get a higher score (shown by the orange bar)</li>
                  <li>Click on any task to expand it to see more details</li>
                  <li>If the task has 0 BCH remaining on its address it can be deleted by anyone</li>
              </ol>
          </InfoContent>
        </InfoSection>

        <HeaderSection>
          <SelectWrapper>
            <StyledSelect 
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              {users.map(user => (
                <option key={user} value={user}>
                  {user}
                </option>
              ))}
            </StyledSelect>
          </SelectWrapper>

          <div>
            <AddUserButton onClick={() => setIsUserModalOpen(true)}>
                Register User
            </AddUserButton>
            <AddTaskButton onClick={() => setIsModalOpen(true)}>
                + Add Task
            </AddTaskButton>
          </div>
        </HeaderSection>

        <TabContainer>
            <TabButton 
                $isActive={activeTab === 'active'}
                onClick={() => setActiveTab('active')}
            >
                Active Tasks
            </TabButton>
            <TabButton 
                $isActive={activeTab === 'old'}
                onClick={() => setActiveTab('old')}
            >
                Old Tasks
            </TabButton>
        </TabContainer>

        <TaskList>
          {(activeTab === 'active' ? tasks : oldTasks)
            .map(task => ({
              task,
              weight: totalDonations > 0 
                ? Number((task.currentDonations * BigInt(100)) / totalDonations)
                : 0
            }))
            .sort((a, b) => {
              // First compare weights
              const weightDiff = b.weight - a.weight;
              // If weights are equal, compare currentDonations
              if (weightDiff === 0) {
                  return Number(b.task.currentDonations) - Number(a.task.currentDonations);
              }
              return weightDiff;
            })
            .map(({ task, weight }) => {
              const bchAmount = Number(task.currentDonations) / 100000000;
              const totalDonations = Number(task.totalDonations) / 100000000;

            return (
              <TaskCard 
                key={task.index}
                onClick={() => toggleCard(task.index)}
                $isExpanded={expandedCards.has(task.index)}
              >
                <TaskBackground />
                <TaskContent>
                  <MinimizedContent>
                    <MinimizeWrapper>
                      <TaskTitle>{task.title}</TaskTitle>
                    </MinimizeWrapper>

                    <MinimizeWrapper>
                      <DonationAmount>
                        <StyledBCHLogo src={BCHLogo} alt="BCH" />
                        {bchAmount.toFixed(8)}
                      </DonationAmount>
                      <WeightBarContainer>
                        <WeightBar width={weight} />
                      </WeightBarContainer>
                    </MinimizeWrapper>
                  </MinimizedContent>

                  {expandedCards.has(task.index) && (
                    <ExpandedContent>
                      <TaskDescription>{task.description}</TaskDescription>
                      <ExpandedWrapper>
                        {activeTab === 'active' && (
                          <QRSection>
                            <QRCode 
                              value={task.address}
                              size={140}
                              bgColor="white"
                              fgColor="black"
                              style={{ width: '100%', height: '100%' }}
                            />
                          </QRSection>
                        )}
                        <ExpandedWrapperColumn>
                          <TotalDonations>
                            Total Donations: 
                          </TotalDonations>
                          <TotalDonatedRow>
                            <StyledBCHLogo src={BCHLogo} alt="BCH" />{totalDonations}
                          </TotalDonatedRow>
                          <Address onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(task.address);
                          }}>
                            {task.address}
                          </Address>

                          {activeTab === 'active' && (
                          <DeleteButton 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteTask(Number(task.index));
                            }}
                            disabled={task.currentDonations !== BigInt(0)}
                            title={task.currentDonations !== BigInt(0) ? "Need 0 BCH balance to delete" : "Delete task"}
                          >
                            Delete
                          </DeleteButton>

                          )}
                        </ExpandedWrapperColumn>
                      </ExpandedWrapper>
                      
                    </ExpandedContent>
                  )}
                </TaskContent>
            </TaskCard>
            );
          })}
        </TaskList>

      </ContentWrapper>

      {/* Add Task Modal */}
      <ModalTask
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ModalContent>
          <ModalTitle>Add New Task</ModalTitle>
          <InputWrapper>
            <Input
              placeholder="Task Title"
              value={newTask.title}
              maxLength={MAX_TITLE_LENGTH}
              onChange={(e) => setNewTask(prev => ({ 
                ...prev, 
                title: e.target.value.slice(0, MAX_TITLE_LENGTH)
              }))}
            />
            <CharCount>{newTask.title.length}/{MAX_TITLE_LENGTH}</CharCount>
          </InputWrapper>

          <InputWrapper>
            <TextArea
              placeholder="Task Description"
              value={newTask.description}
              onChange={(e) => setNewTask(prev => ({ 
                ...prev, 
                description: e.target.value.slice(0, MAX_DESCRIPTION_LENGTH)
              }))}
            />
            <CharCount>{newTask.description.length}/{MAX_DESCRIPTION_LENGTH}</CharCount>
          </InputWrapper>

          <ModalButtons>
            <CancelButton onClick={() => setIsModalOpen(false)}>
              Cancel
            </CancelButton>
            <AddButton 
              onClick={handleAddTask}
              disabled={isProcessing || !selectedUser}
            >
              Add Task
            </AddButton>
          </ModalButtons>
          
        </ModalContent>

      </ModalTask>

      {/* Register User Modal */}
      <ModalTask
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
      >
        <UserModalContent>
          <ModalTitle>Register New User</ModalTitle>
          <InputWrapper>
            <Input
                placeholder="Username"
                value={newUser.username}
                onChange={(e) => setNewUser(prev => ({
                  ...prev,
                  username: e.target.value
                }))}
            />
          </InputWrapper>

          <InputWrapper>
            <XPubInput
              placeholder="xpub..."
              value={newUser.xpub}
              onChange={(e) => setNewUser(prev => ({
                ...prev,
                xpub: e.target.value
              }))}
            />
          </InputWrapper>

          <ModalButtons>
            <CancelButton onClick={() => setIsUserModalOpen(false)}>
              Cancel
            </CancelButton>
            <AddButton 
              onClick={handleAddUser}
              disabled={isProcessing}
            >
              {isProcessing ? 'Registering...' : 'Register User'}
            </AddButton>
          </ModalButtons>
        </UserModalContent>
    </ModalTask>
    </LoansContainer>
  );
};

export default Tasks;