import styled, { keyframes } from 'styled-components';
import CasualLogo from '../../assets/img/casualLogo.png';
import BCHLogo from '../../assets/img/bch.png';
import ImageSpinner from '../../assets/img/BCH2.gif';

interface LogoDivProps {
    logourl: string;
  }
  interface PledgeButtonProps {
    $isActive?: boolean;
  }
  interface CampaignTypeButtonProps {
    $isActive?: boolean;
  }
  interface DetailsButtonProps {
    $isActive?: boolean;
  }

export const sheenAnimation = keyframes`
0%, 25% { // Sheen visible and starts moving (0% to 25% of the animation duration)
  left: -30%;
}
50% { // Sheen finishes moving (at 50% of the animation duration)
  left: 100%;
}
50.1%, 100% { // Sheen is not visible or stationary for the rest of the duration
  left: -30%;
}
`;
export const fillAnimation = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh; // Full viewport height
  width: 100%; // Full viewport width
  background: #000;
  border: 0px solid yellow;
  a {
    color: #0AC18E;
    text-decoration: none;
    &:hover {
      color: #09af81; /* Hover link color */
    }
  }
`;
export const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100vh;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
`;
export const StyledBCH = styled.div`
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  top: 3px;
  display: flex;
  height: 35px;
  width: 35px;
  border: 0px solid red;
  @media (max-width: 600px) {
    height: 28px;
    width: 28px;
  }
`;
export const StyledCards = styled.div`
  border: 0px solid red;
  color: white;
  height: 100%;
  width: 90%;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: 10px;
  margin-bottom: 10px;
  border-radius: 0px 0px 0px 0px;
`;
export const DetailsButton = styled.button<DetailsButtonProps>`
  position: absolute;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
  background-color: ${(props) => (props.$isActive ? '#09af81' : '#0AC18E')};
  color: #F8F8F8;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: 150px;
  min-width: 50px;
  height: 32px;
  top: 0px;
  right: 0px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
  }
  @media (max-width: 520px) {
    width: 100px;
  }
`
export const CampaignCard = styled.div`
  background-color: #000;
  border: 1px solid #00cc66;
  margin: 5px;
  color: white;
  height: 315px;
  width: 500px;
  max-width: 500px;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 15px 0px 15px 15px;
  &:hover {
    border: 1px solid #fff;
    ${DetailsButton} {
      display: block;
      opacity: 1;
      visibility: visible;
      pointer-events: auto; // Enable click events on hover
    }
  }
`;
export const StyledExpiredTitle = styled.div`
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 40px;
  border: 0px solid red;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  position: relative;
  top: 5px;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #F8F8F8;
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 520px) {
    font-size: 14px;
  }
`;
export const StyledCampaignID = styled.div`
  height: 25px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  top: 2px;
  padding-left: 5px;
  text-decoration: none;
  text-align: right;
  overflow: hidden;
  color: #F8F8F8;
`;
export const StyledOwner = styled.div`
  position: absolute;
  right: 5px;
  top: 2px;
  height: 25px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
  text-decoration: none;
  text-align: right;
  overflow: hidden;
  color: #F8F8F8;
`;
export const StyledAdBox = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
  top: 125px;
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;
export const StyledDescription = styled.div`
  position: relative;
  padding: 5px;
  width: 98%;
  height: 120px;
  top: 10px;
  border: 0px solid yellow;
  font-size: 15px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  @media (max-width: 550px) {
    font-size: 14px;
  }
`;
export const StyledFooterRow = styled.div`
  position: relative;
  height: 25px;
  top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #0AC18E;
  border-left: 0px solid red;
  border-right: 0px solid red;
  border-bottom: 0px solid red;
  z-index: 2;
`;
export const StyledRow = styled.div`
  position: relative;
  top: 0px;
  height: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
  padding-left: 5px;
  border: 0px solid blue;
`;
export const StyledRaisedRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
export const StyledCampaignButtonRow = styled.div`
  position: relative;
  top: 5px;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
  padding-left: 5px;
  border: 0px solid red;
  align-items: center;
  justify-content: center;
`;
export const CampaignTypeButton = styled.button<CampaignTypeButtonProps>`
  position: relative;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  display: flex;
  background-color: ${(props) => (props.$isActive ? '#0AC18E' : '#000')};
  color: #F8F8F8;
  border: 1px solid ${(props) => (props.$isActive ? '#fff' : '#0AC18E')};;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: 150px;
  min-width: 50px;
  height: 32px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
    border: 2px solid #fff;
  }
`
export const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.logourl});
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 40%;
  border: 0px solid orange;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
export const StyledName = styled.div`
  position: relative;
  width: 340px;
  height: 40px;
  line-height: 18px;
  display: flex;
  border: 0px solid white;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  color: #F8F8F8;
  @media (max-width: 570px) {
    font-size: 14px;
    width: 255px;
  }
`;
export const StyledEnd = styled.div`
  position: absolute;
  right: 15px;
  display: flex;
  border: 0px solid pink;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  color: #F8F8F8;
  @media (max-width: 550px) {
    font-size: 14px;
  }
`;
export const ProgressBar = styled.div`
  position: relative;
  height: 45px;
  width: 100%;
  top: 0px;
  display: flex;
  justify-content: center;
  background-color: #84b6a8;
  border: 0px solid white;
  overflow: hidden;
  z-index: 2;
  flex-direction: row;
`;
export const ProgressBarText = styled.div`
  position: absolute;
  top: -2px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border: 0px solid red;
  overflow: hidden;
  z-index: 3;
`;
export const ProgressBarSubText = styled.div`
  position: absolute;
  top: 15px;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border: 0px solid red;
  overflow: hidden;
  z-index: 3;
`;
export const Progress = styled.div<{ progress: number }>`
  display: flex;
  position: absolute;
  left: 0px;
  background-image: linear-gradient(to top, #024d38, #51e0b8); // Gradient for glass effect
  width: ${props => props.progress}%;
  height: 45px;
  overflow: hidden;
  z-index: 2;
  justify-content: left;
  align-items: left;
  animation: ${fillAnimation} 2s ease-out; // Apply the filling animation

  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3), // Inner shadow for depth
              inset 0 2px 2px rgba(0,0,0,0.2); // More depth

  transform-origin: left;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    animation: ${sheenAnimation} 3.5s ease-in-out infinite;
  }
`;
export const StyledSpinner = styled.div`
  position: relative;
  top: 50px;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  background-image: url(${ImageSpinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
  width: 150px;
  display: flex;
  border: 1px solid #0AC18E;
  border-radius: 10px 0px 10px 10px;
  z-index: 100;
`;
//Stats Row
export const StatsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  padding-top: 25px;
  background: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid #0AC18E;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
`;
export const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(10, 193, 142, 0.1);
  border: 1px solid #0AC18E;
  @media (max-width: 600px) {
    min-width: 150px;
    padding: 5px;
  }
`;
export const StatValue = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #0AC18E;
  margin-bottom: 5px;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const StatLabel = styled.div`
  font-size: 16px;
  color: #F8F8F8;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;