import styled, { keyframes } from 'styled-components';
import CasualLogo from '../../assets/img/casualLogo.png';
import BCHLogo from '../../assets/img/bch.png';
import ImageSpinner from '../../assets/img/BCH2.gif';
import Spinner from '../../assets/img/spinner.gif';

interface StyledSpacerProps {
    height: number;
  }
  interface StyledLogoProps {
    size: number;
  }
  interface LogoDivProps {
    logoUrl: string;
  }
  interface MenuButtonProps {
    isactive?: boolean;
    width: number;
  }
  interface CancelButtonProps {
    isactive?: boolean;
  }
  interface StyledNFTProps {
    isSelected: boolean;
  }
  interface StyledNavBarEntryProps {
    isSelected: boolean;
  }
  interface StyledDonateName {
    nameLength: number;
  }
  interface StyledEndProps {
    pledgedAmount: number;
  }
  
  export const sheenAnimation = keyframes`
  0%, 25% { // Sheen visible and starts moving (0% to 25% of the animation duration)
    left: -30%;
  }
  50% { // Sheen finishes moving (at 50% of the animation duration)
    left: 100%;
  }
  50.1%, 100% { // Sheen is not visible or stationary for the rest of the duration
    left: -30%;
  }
  `;
  export const fillAnimation = keyframes`
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  `;
  export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 660px;
    width: 100%;
    background: #000;
    border: 0px solid yellow;
  `;
  export const StyledBreakline = styled.hr`
    position: relative;
    display: flex;
    border: none;
    height: 2px;
    background-color: #0AC18E;
    width: 95%;
    margin: 10px auto;
    top: 7px;
  `;
  export const StyledBackground = styled.div`
    background-image: url(${CasualLogo});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    display: flex;
    height: 816px;
    width: 100%;
    border: 0px solid red;
    z-index: 0;
    opacity: 0.4;
  `;
  export const StyledBlack = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.7;
    background: #000;
    border-radius: 10px;
  `;
  export const StyledEnd = styled.div<StyledEndProps>`
    position: relative;
    height: 25px;
    display: flex;
    border: 0px solid yellow;
    font-weight: 700;
    font-size: 18px;
    color: ${props => props.pledgedAmount > 0 ? '#0AC18E' : '#ea1313'};
    padding-left: 5px;
  `;
  export const StyledEndBlock = styled.div`
    position: relative;
    height: 25px;
    display: flex;
    border: 0px solid yellow;
    font-weight: 400;
    font-size: 14px;
    color: #F8F8F8;
    padding-left: 5px;
  `;
  export const CampaignNotFound = styled.div`
    position: relative;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 50px;
    width: 600px;
    color: #F8F8F8;
    background: #000;
    opacity: 0.8;
    border: 1px solid #0AC18E;
    @media (max-width: 670px) {
      width: 360px;
      height: 70px;
    }
  `;
  export const CampaignDetailContainer = styled.div`
    position: relative;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 650px;
    max-width: 1050px;
    min-width: 1000px;
    width: 80%;
    border: 0px solid red;
    @media (max-width: 1000px) {
      width: 650px;
      min-width: 650px;
    }
    @media (max-width: 670px) {
      width: 100%;
      min-width: 300px;
    }
  `;
  export const StyledNavBar = styled.div`
    position: relative;
    right: 110px;
    top: 0px;
    color: #F8F8F8;
    display: flex;
    flex-direction: row;
    border: 0px solid red;
    width: 700px;
    height: 30px;
    margin-top: 5px;
    justify-content: left;
    z-index: 3;
    @media (max-width: 1000px) {
      width: 650px;
      right: 0px;
    }
    @media (max-width: 670px) {
      width: 92%;
  
    }
  `;
  export const StyledNavBarEntry = styled.div<StyledNavBarEntryProps>`
    border: 2px solid #0AC18E;
    border-radius: 15px 15px 0px 0px;
    border-bottom: ${props => props.isSelected ? '0px' : '2px solid #0AC18E;'};
    background: ${props => props.isSelected ? '#000' : '#000'};
    width: 33%;
    height: 30px;
    text-align: center;
    justify-content: center;
    z-index: 3;
    opacity: ${props => props.isSelected ? '1.0' : '0.5'};
    &:hover {
      opacity: ${props => props.isSelected ? '1.0' : '0.8'};
    }
  `;
  export const StyledNavBarEmpty = styled.div`
    position: relative;
    top: 30px;
    border-bottom: 2px solid #0AC18E;
    width: 33%;
    height: 0px;
    z-index: 3;
  `;
  export const StyledSpacer = styled.div<StyledSpacerProps>`
    position: relative;
    min-height: ${props => props.height}px;
    display: flex;
    flex-direction: row;
    border: 0px solid orange;
  `;
  export const Column = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    border: 0px solid orange;
    @media (max-width: 670px) {
      width: 100%;
      justify-content: center;
    }
  `;
  export const Row = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border: 0px solid yellow;
  `;
  export const StyledRefundButtons = styled.div`
    position: absolute;
    left: 0px;
    top: 25px;
    width: 110px;
    height: 90px;
    display: flex;
    flex-direction: column;
    border: 0px solid orange;
    justify-content: center;
    align-items: center;
    @media (max-width: 1000px) {
      width: 150px;
    }
    @media (max-width: 670px) {
      width: 120px;
      min-width: 120px;
    }
  `;
  export const BannerRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border: 0px solid yellow;
    margin-left: 20px;
    @media (max-width: 670px) {
      margin-left: 5px;
    }
  `;
  export const BannerImage = styled.div<LogoDivProps>`
    background-image: url(${props => props.logoUrl});
    background-size: cover;
    width: 750px;
    height: 180px;
    background-color: #ddd; // Replace with your banner image
    border: 1px solid #0AC18E;
    box-shadow: 7px 7px 5px rgba(0,0,0,0.4);
    @media (max-width: 1000px) {
      width: 500px;
      height: 120px;
    }
    @media (max-width: 670px) {
      width: 90%;
      margin-bottom: 10px;
    }
  `;
  export const UserContainer = styled.div`
    align-items: center;
    width: 100%;
    border: 0px solid white;
    text-align: center;
    @media (max-width: 670px) {
      position: absolute;
      right: 60px;
      top: -10px;
    }
  `;
  export const LogoImage = styled.div<LogoDivProps>`
    background-image: url(${props => props.logoUrl});
    background-size: cover;
    height: 150px; 
    width: 150px; 
    border: 0px solid #0AC18E;
    border-radius: 10px;
    @media (max-width: 1000px) {
      width: 100px;
      height: 100px;
    }
    @media (max-width: 670px) {
      width: 70px;
      height: 70px;
    }
  `;
  export const UserName = styled.div`
    color: #F8F8F8;
    height: 40px;
    width: 150px;
    font-size: 1.0em;
    font-weight: 600;
    border: 0px solid red;
    overflow: hidden;
    @media (max-width: 1000px) {
      font-size: 0.8em
    }
    @media (max-width: 670px) {
      position: absolute;
      width: 250px;
      height: 15px;
      top: -7px;
      right: 5px;
      font-size: 0.7em;
      text-align: right;
    }
  `;
  export const ProgressBar = styled.div`
    position: relative;
    display: flex;
    width: 920px;
    background-color: #84b6a8;
    justify-content: center;
    height: 35px;
    border: 2px solid #0AC18E;
    border-top: 0px;
    border-radius: 0px 0px 15px 15px;
    overflow: hidden;
    z-index: 2;
    flex-direction: row;
    color: #F8F8F8;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    cursor: pointer;
    @media (max-width: 1000px) {
      width: 650px;
    }
    @media (max-width: 670px) {
      width: 90%;
    }
  `;
  export const StyledPledgeBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flext-start;
    position: relative;
    gap: 10px;
    width: 100%;
    max-width: 920px;
    border: 0px solid red;
    opacity: 0.9;
    margin-bottom: 10px;
    margin-top: 10px;
    justify-content: space-between;
    @media (max-width: 1000px) {
      justify-content: center;
    }
    @media (max-width: 670px) {
      width: 90%;
      min-width: 300px;
    }
  `;
  export const DescriptionAndLinksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: 0px;
    top: 0px;
    gap: 5px;
    width: 925px;
    justify-content: space-between;
    border: 0px solid white;
    opacity: 0.9;
    min-height: 550px;
    overflow: visible;
    margin-bottom: 50px;
    @media (max-width: 1000px) {
      width: 650px;
    }
    @media (max-width: 670px) {
      width: 92%;
    }
  `;
  export const StyledCampaignTitle = styled.div`
    position: relative;
    left: 0px;
    width: 100%;
    color: #F8F8F8;
    border-bottom: 1px solid #0AC18E;
    border-radius: 8px 8px 0px 0px;
    background: #0AC18E;
    height: 40px;
    z-index: 3;
    align-text: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  `;
  export const StyledTitleDiv = styled.div`
    position: relative;
    top: 0px;
    width: 920px;
    color: #F8F8F8;
    border: 2px solid #0AC18E;
    border-radius: 8px 8px 0px 0px;
    background: #000;
    height: 28px;
    z-index: 3;
    align-text: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
    @media (max-width: 1000px) {
      width: 650px;
      font-size: 18px;
    }
    @media (max-width: 670px) {
      width: 90%;
      font-size: 16px;
    }
  `;
  export const StyledDescription = styled.div`
    position: relative;
    padding: 10px;
    flex: 3;
    color: white;
    border: 2px solid #0AC18E;
    border-top: 0px;
    z-index: 2;
    background: #000;
    width: 100%;
    overflow-wrap: break-word;
    iframe {
      width: 100%;
      height: 400px;
      max-width: 800px;
      border: none;
    }
    img {
      max-width: 100%;
      max-height: 800px;
    }
    a {
      color: #0AC18E;
      &:hover {
        color: #09af81;
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
    @media (max-width: 670px) {
      width: 400px;
    }
  `;
  export const StyledPledges = styled.div`
    position: relative;
    width: 210px;
    max-width: 210px;
    min-height: 300px;
    height: 560px;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #F8F8F8;
    background: #000;
    border: 2px solid #0AC18E;
    border-radius: 10px 10px 0px 0px;
    justify-content: flex-start;
    gap: 5px;
    overflow: visible;
    overflow-x: hidden;
    overflow-y: auto;
    a {
      color: #0AC18E;
      &:hover {
        color: #09af81;
      }
    }
    @media (max-width: 1000px) {
      width: 100%;
      max-width: 100%;
      left: 1px;
    }
    @media (max-width: 670px) {
      width: 400px;
    }
  `;
  //Progress Bar
  export const ProgressBarText = styled.div`
    position: absolute;
    top: -2px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    border: 0px solid red;
    padding-right: 5px;
    overflow: hidden;
    z-index: 3;
  `;
  export const ProgressBarSubText = styled.div`
    position: absolute;
    top: 15px;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    border: 0px solid red;
    padding-right: 5px;
    overflow: hidden;
    z-index: 3;
  `;
  export const Progress = styled.div<{ progress: number }>`
    display: flex;
    position: absolute;
    left: 0px;
    background-image: linear-gradient(to top, #024d38, #51e0b8); // Gradient for glass effect
    width: ${props => props.progress}%;
    height: 35px;
    overflow: hidden;
    z-index: 2;
    justify-content: left;
    align-items: left;
    animation: ${fillAnimation} 2s ease-out; // Apply the filling animation
    box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3), // Inner shadow for depth
                inset 0 2px 2px rgba(0,0,0,0.2); // More depth
  
    transform-origin: left;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30%;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
      animation: ${sheenAnimation} 3.5s ease-in-out infinite;
    }
    &:hover {
      background-image: linear-gradient(to top, #047c5b, #76ffd9);
    }
  `;
  export const MenuButton = styled.button<MenuButtonProps>`
    position: relative;
    display: flex;
    background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
    color: #F8F8F8;
    border: 1px solid #fff;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
    width: ${props => props.width}px;
    min-width: 50px;
    height: 32px;
    top: 7px;
    margin-bottom: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
    &:hover {
      background-color: #0cd9a0;
      border: 2px solid #fff;
    }
    @media (max-width: 1000px) {
      width: 125px;
    }
    &:disabled {
      background: #aaa;
      cursor: not-allowed;
      border: 2px solid #aaa;
      width: 110px;
    }
  `
  export const CancelButton = styled.button<CancelButtonProps>`
    position: relative;
    display: flex;
    background-color: ${(props) => (props.isactive ? '#ff0000' : '#aa0000')};
    color: #F8F8F8;
    border: 1px solid #fff;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
    width: 150px;
    min-width: 50px;
    height: 32px;
    top: 7px;
    margin-bottom: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
    &:hover {
      background-color: #ff0000;
      border: 2px solid #fff;
    }
    @media (max-width: 1000px) {
      width: 125px;
    }
    &:disabled {
      background: #aaa;
      cursor: not-allowed;
      border: 2px solid #aaa;
    }
  `
  export const StyledPledgeAmount = styled.div`
    position: relative;
    top: 5px;
    left: 5px;
    height: 20px;
    display: flex;
    width: 110px;
    border: 0px solid red;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    text-decoration: none;
    color: #F8F8F8;
    input { 
      width: 100%;
      border: inherit;
      font-size: inherit;
      color: inherit;
    }
  `;
  export const StyledPledgeInput = styled.input`
    width: 140px;
    height: 20px;
    border: 0px solid red;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    color: #F8F8F8;
    background-color: white;
    color: black;
    border-radius: 5px;
    padding: 4px;
    margin-left: 7px;
    margin-top: 5px;
  `;
  export const StyledPledgeLogo = styled.div<StyledLogoProps>`
    position: relative;
    background-image: url(${BCHLogo});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    display: flex;
    border: 0px solid red;
  `;
  //Styled NFT UTXO
  export const StyledNFT = styled.div<StyledNFTProps>`
    position: relative;
    background-color: ${(props) => (props.isSelected ? '#0AC18E' : 'transparent')};
    color: white;
    padding: 0px;
    display: flex;
    flex-direction: row;
    width: 98%;
    height: 30px;
    margin-top: 5px;
    border: 2px solid  ${(props) => (props.isSelected ? '#fff' : '#0AC18E')};
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 5px rgba(255,255,255,0.3);
    &:hover {
      background-color: #0AC18E;
      border: 2px solid #fff;
    }
  `;
  export const StyledNFTTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    position: relative;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 0px solid yellow;
    height: 20px;
    padding-left: 5px;
    padding: 0px;
    text-decoration: none;
    color: #F8F8F8;
  `;
  export const StyledNFTInfo = styled.div`
    position: relative;
    width: 100%;
    height: 150px;
    padding: 0px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #000;
    color: #F8F8F8;
    border: 0px solid red;
    z-index: 1;
    display: flex;
    flex-direction: column;
  `;
  export const StyledSatsInfo = styled.div`
    position: relative;
    width: 100%;
    padding: 0px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #000;
    color: #F8F8F8;
    opacity: 0.7;
    border: 0px solid red;
    z-index: 1;
    display: flex;
    flex-direction: columns;
  `;
  export const StyledNFTText = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding-left: 5px;
    text-align: center;
    color: white;
    border: 0px solid red;
    font-size: 12px;
    font-weight: 600;
    overflow-wrap: break-word;
    word-wrap: break-word;
    flex-shrink: 1;
    white-space: normal;
  `;
  export const StyledNFTTextRow = styled.div`
    display: flex;
    flex-direction: row; 
    align-items: center;
  `;
  export const StyledSats = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    margin-left: 10px;
    left: 100px;
    color: white;
    border: 0px solid red;
    font-size: 14px;
    font-weight: 600;
  `;
  export const StyledNFTContainer = styled.div`
    position: absolute;
    top: 22px;
    right: 0px;
    display: flex;
    width: 235px;
    height: 92px;
    flex: 1 1 auto;
    flex-wrap: wrap;
    border-radius: 10px;
    border: 0px solid red;
    border-radius: 0px;
    overflow-x: auto;
    overflow-y: auto;
    flex-direction: row;
    align-items: flex-start;
    @media (max-width: 1000px) {
      width: 70%;
      justify-content: center;
    }
    @media (max-width: 670px) {
      width: 60%;
      min-width: 60%;
    }
  `;
  export const StyledRefreshButton = styled.button<MenuButtonProps>`
  position: relative;
  top: -10px;
  background-color: #000;
  color: #F8F8F8;
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  z-index: 10;
  width: 75px;
  height: 25px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0AC18E;
  }
  &:active {
    background-color: red;
  }
  `
  export const StyledFailButton = styled.button<MenuButtonProps>`
  position: absolute;
  background-color: #bb0000;
  color: #F8F8F8;
  border: 1px solid #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  z-index: 10;
  width: 75px;
  height: 25px;
  top: 4px;
  right: 30px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #990000;
  }
  `
  export const StyledCampaignID = styled.div`
    position: relative;
    height: 25px;
    display: flex;
    border: 0px solid blue;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    color: #F8F8F8;
    padding-left: 5px;
  `;
  export const Input = styled.input`
    margin: 10px;
    padding: 5px;
  `;
  export const StyledDescriptionCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0px solid red;
  `;
  export const StyledSpinner = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url(${ImageSpinner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
    width: 150px;
    display: flex;
    border: 1px solid #0AC18E;;
    border-radius: 10px;
    z-index: 100;
    @media (max-width: 1000px) {
      width: 100px;
      height: 100px;
      right: 50px;
    }
    @media (max-width: 670px) {
      width: 70px;
      height: 70px;
      left: 0px;
    }
  `;
  export const StyledButtonSpinner = styled.div`
    position: relative;
    background-image: url(${Spinner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 45px;
    width: 45px;
    display: flex;
    border: 0px solid red;
  `;
  export const StyledRowDetails = styled.div`
    position: relative;
    display: flex;
    width: 260px;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    border: 2px solid #0AC18E;
    border-radius: 10px;
    max-height: 120px;
  `;
  export const StyledRowPledge = styled.div`
    position: relative;
    display: flex;
    width: 200px;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    border: 2px solid #0AC18E;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    max-height: 120px;
    @media (max-width: 1000px) {
      height: 120px;
    }
    @media (max-width: 670px) {
      height: 120px;
      max-height: 120px;
    }
  `;
  export const StyledRowNFTs = styled.div`
    position: relative;
    display: flex;
    width: 300px;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    border: 2px solid #0AC18E;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 120px;
  `;
  export const StyledTitle = styled.div`
    position: absolute;
    text-align: center;
    width: 100%;
    top: 0px;
    background: #0AC18E;
    border-radius: 8px 8px 0px 0px;
    color: #F8F8F8;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #0AC18E;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  `;
  export const StyledDonation = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    color: #F8F8F8;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #0AC18E;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  `;
  export const StyledDonateName = styled.div<StyledDonateName>`
    width: 100%;
    font-size: ${(props) => (props.nameLength > 15 ? '12px' : '16px')};
    font-weight: ${(props) => (props.nameLength > 15 ? '700' : '600')};
    border: 0px solid blue;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
    word-wrap: break-word;
  `;
  export const StyledDonateAmount = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #F8F8F8;
    font-size: 16px;
    font-weight: 600;
    border: 0px solid yellow;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
    justify-content: center;
    gap: 5px;
    padding: 3px;
  `;
  export const StyledPledgeID = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 30px;
    height: 20px;
    color: #F8F8F8;
    background: #0AC18E;
    font-size: 14px;
    padding-left: 4px;
    font-weight: 600;
    border: 0px solid yellow;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-text: center;
  `;
  export const StyledDonateMessage = styled.div`
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    border: 0px solid red;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
    word-wrap: break-word;
    padding-left: 3px;
    padding-right: 5px;
  `;
  export const StyledDescriptionTabs = styled.div`
    display: flex;
    position: relative;
    width: 700px;
    left: 3px;
    border: 0px solid orange;
    @media (max-width: 1000px) {
      width: 650px;
      left: 0px;
    }
    @media (max-width: 670px) {
      width: 100%;
    }
  `;
  export const UpdateTextarea = styled.textarea`
    width: 90%;
    height: 200px;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #0AC18E;
    border-radius: 4px;
    background-color: #222;
    color: white;
    resize: vertical;
  `;
  export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: white;
  
    input {
      margin-right: 8px;
    }
  `;
  export const UrlInput = styled.input`
    width: 90%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #0AC18E;
    border-radius: 4px;
    background-color: #222;
    color: white;
  `;
  export const SubmitButton = styled.button`
    position: relative;
    top: 20px;
    margin-bottom: 20px;
    padding: 8px 16px;
    background-color: #0AC18E;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: #09af81;
    }
  `;
  export const UpdateBox = styled.div`
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 5px;
    width: 90%;
    border: 1px solid #0AC18E;
    border-radius: 5px;
  `;
  export const UpdateNumber = styled.h2`
    color: #0AC18E;
    margin-bottom: 10px;
  `;
  export const UpdateText = styled.div`
   img {
      max-width: 100%;
      height: auto;
    }
  
    a {
      color: #0AC18E;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  
    iframe {
      max-width: 100%;
    }
  
    ul, ol {
      padding-left: 20px;
    }
  `;