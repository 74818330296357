import React, { useState, useEffect } from 'react';
//contract artifacts
import ArtifactCashStarterManager from '../../contracts/FundMeV8_Mainnet/json/CashStarterManager.json';
//blockchain
import { ElectrumNetworkProvider, Contract, TransactionBuilder, Network } from 'cashscript';
import { decodeCashAddress, encodeCashAddress, stringify } from '@bitauth/libauth';
//functions
import consolidateUTXOs from '../../functions/consolidateUTXOs';
import managerInitialize from '../../functions/managerInitialize';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import axios from 'axios';
import RichTextEditor from '../../components/RichTextEditor';
//Calendar selector
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { environmentUrl } from '../../constants/environment';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { 
  StyledCreate,
  StyledBackground,
  StyledWizard,
  StyledTextSteps,
  StyledTextBackground,
  StyledText,
  StyledTextCenter,
  StyledWarning,
  StyledSubText,
  StyledNoteTitle,
  StyledNoteText,
  StyledNote,
  StyledInputs,
  StyledRow,
  StyledLogos,
  StyledLogo,
  StyledButton,
  StyledNoteButton,
  Container1,
  InputTitle,
  InputOwner,
  InputShortDescription,
  LogoPreview,
  BannerPreview1,
  ErrorText1,
  ClearButton,
  StyledButtonSpinner,
  StyledUL,
  StyledBCHLogo,
  DatePickerStyles
} from './Create.styles';

const Create: React.FC = () => {
  //user
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  //contracts
  const [transactionBuilder, setTransactionBuilder] = useState<TransactionBuilder>();
  const [contractsOK, setContractsOK] = useState(false);
  const [contractManager, setContractManager] = useState<Contract>();
  //user input values
  const [fundTarget, setFundTarget] = useState('');
  const [endingBlock, setEndingBlock] = useState(0);
  //blockchain stuff
  const [blockHeight, setBlockHeight] = useState<number>(0);
  //user input for campaign
  const [name, setName] = useState('');
  const [owner, setOwner] = useState('');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [logo, setLogo] = useState('');
  const [banner, setBanner] = useState('');
  const [txPending, setTxPending] = useState(false);
  const [error, setError] = useState('');
  //const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 30);
    return futureDate;
  });
  const MAX_CAMPAIGN_SIZE_MB = 25; // Maximum allowed size in MB

////////////////////////////////////////////////// 
////////// Initialize Transaction Builder
//////////////////////////////////////////////////
  if (!transactionBuilder) {
    const provider = new ElectrumNetworkProvider(Network.MAINNET);
    //const provider = new ElectrumNetworkProvider(Network.CHIPNET);
    setTransactionBuilder(new TransactionBuilder({ provider }));
  }

//////////////////////////////////////////////////
////////// Initial UseEffect: Initialize Contracts on page load
//////////////////////////////////////////////////
  useEffect(() => {
    console.log('1. useEffect called');
    async function checkAndCompileContracts() {
      //delay to allow electrum to stabilize
      setTimeout(async () => {
        if (!electrumServer) {
          console.log('Electrum server is not yet available.');
          return;
        }
        console.log('2. compiling contracts...');
        if (contractsOK === false) {
          try {
            const compiledCashStarterManager = await compileContract(ArtifactCashStarterManager, []);
            setContractManager(compiledCashStarterManager);
            setContractsOK(true);
            console.log('3. contracts OK');
          } catch (error) {
            console.log('contract compiling error: ' + error);
          }
        }
        const blockHeight = await electrumServer.getBlockHeight();
        setBlockHeight(blockHeight);

        handleEndBlockUpdate(endDate, blockHeight);
      }, 2000);
    }
  
    checkAndCompileContracts();
  }, [electrumServer]);

//////////////////////////////////////////////////
////////// Sign Transaction with WalletConnect
//////////////////////////////////////////////////
async function signTransaction(options: any) {
  console.log('signing transaction...');
  try {
    if (walletConnectInstance) {
      const params = JSON.parse(stringify(options));
      console.log('wc params:');
      console.log(params);
      const result = await walletConnectInstance.request({
        chainId: connectedChain,
        topic: walletConnectSession.topic,
        request: {
          method: "bch_signTransaction",
          params: params,
        },
      });
      return result;
    }
  } catch (error) {
    console.log('signTransation error: ' + error);
    toast.info(`Error signing transaction: \n${error}`);
    return undefined;
  }
}

//////////////////////////////////////////////////
//////////Convert address to token address
//////////////////////////////////////////////////
/*
function toTokenAddress(address: string) {
  console.log('toTokenAddress() called for address: ' + address);
  const addressInfo: any  = decodeCashAddress(address);
  const pkhPayoutBin = addressInfo.payload;
  const prefix = 'bitcoincash';
  const tokenAddress = encodeCashAddress(prefix, "p2pkhWithTokens", pkhPayoutBin);
  console.log('toTokenAddress() converted it to: ' + tokenAddress);
  return tokenAddress;
}
*/
//////////////////////////////////////////////////
//////////Compile an artifact into a CashScript Contract object
//////////////////////////////////////////////////
async function compileContract(contractArtifact: any, args: any[]) {
  const contract = new Contract(contractArtifact, args, {provider: electrumServer, addressType: 'p2sh32'});
  return contract;
}

/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// Contract Functions  /////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////

async function handleConsolidateUtxos() {
  setTxPending(true);

  if (electrumServer && usersAddress && transactionBuilder) { 
    const signResult = await consolidateUTXOs({electrumServer, usersAddress, transactionBuilder, signTransaction, setError: (msg: string) => toast.info(msg) });
    const rawTx = signResult.signedTransaction; 
    console.log('signedTransaction from walletconnect: ');
    console.log(signResult);
    
    electrumServer.sendRawTransaction(rawTx).then((result: string) => {
      console.log('Broadcasted, txid: ' + result);
    }).catch((error: Error) => {
      toast.info(`Error consolidating: \n${error}`);
      console.log('Error consolidating: ', error);
    });

  } else {
    console.log('Error consolidating, connect wallet or refresh?');
    toast.info('Error consolidating, connect wallet or refresh?');
  }
  
  setTxPending(false);
}

const handleCreateCampaign = async () => {
  setTxPending(true);

  if (electrumServer && usersAddress && name != '' && owner != '' && description != '' && shortDescription != '' && logo != '' && banner != '') {
    // Check the size of the campaign data
    const campaignData = JSON.stringify({
      name,
      owner,
      description,
      shortDescription,
      logo,
      banner
    });
    const campaignSizeMB = Buffer.byteLength(campaignData, 'utf8') / (1024 * 1024);

    if (campaignSizeMB > MAX_CAMPAIGN_SIZE_MB) {
      toast.info(`Error: Campaign size (${campaignSizeMB.toFixed(2)} MB) exceeds max of (${MAX_CAMPAIGN_SIZE_MB} MB)`);
      setTxPending(false);
      console.log('campaignSize error, size(MB): ', campaignSizeMB);
      return;
    }

    // Continue with campaign creation
    const pubkeyhash = getAddressesPKH(usersAddress)!;
    const endBlock = endingBlock.toString();

    const signResult = await managerInitialize({ electrumServer, usersAddress, contractManager, signTransaction, pubkeyhash, fundTarget, endBlock, setError: (msg: string) => toast.info(msg) })
  
    if (signResult!.signResult) {
      const rawTx = signResult!.signResult.signedTransaction;
      const campaignID = signResult!.newCampaignID.toString();

      console.log('signedTransaction from walletconnect: ');
      console.log(rawTx);

      try {
        const result = await electrumServer.sendRawTransaction(rawTx);
        console.log('Broadcasted, txid: ' + result);

        // Save to external server
        await axios.post(environmentUrl + '/save-campaign', { campaignID, name, owner, shortDescription, description, logo, banner, txid: result, usersAddress });
        toast.info(`New Campaign created! TxID:\n${result}`);
      } catch (error) {
        console.log('Error creating campaign: ', error);
        toast.info(`Error creating campaign:\n${error}`);
      }

    } else {
      console.log('Error creating campaign: ', error);
      toast.info(`Error creating campaign. ` + error);
    }

  } else {
    console.log('Error creating campaign. Are you connected and provided all the campaign details?');
    toast.info(`Error creating campaign. Are you connected and provided all the campaign details?`);
  }
  
  setTxPending(false);
}

const handleSetFundTarget = (text: string) => {
  let newValue = text.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, '$1'); // Allow numbers and a single decimal point

  // Check if there's a decimal point, and if so, limit the length after it to 8
  const decimalIndex = newValue.indexOf('.');
  if (decimalIndex !== -1) {
    const integerPart = newValue.substring(0, decimalIndex);
    const decimalPart = newValue.substring(decimalIndex + 1, decimalIndex + 9); // Grab up to 8 decimal places
    newValue = `${integerPart}.${decimalPart}`;
  }

   // Parse the newValue as a floating-point number and check against the limit
   const numericValue = parseFloat(newValue);
   if (numericValue > 1407374) { // If the value exceeds 1407374, limit it to 1407374. Effectively restricts fundTarget to 5bytes, leaving the 6th byte for fundTarget to be empty (00) to satisfy minimally-encoded VM numbers
     newValue = '1407374';
   }

  setFundTarget(newValue);
};

//////////////////////////////////////////////////
/////Wallet Functions
//////////////////////////////////////////////////
  //convert address to pubkeyhash
  function getAddressesPKH(address: string) {
    try {
      const decoded = decodeCashAddress(address);
      if (typeof decoded === 'string') {
        console.log('Invalid address format');  // Handle error - decoded should be an object for a valid address
        toast.info('Error: Invalid address format');
        return null;
      }

      // Convert Uint8Array to a hexadecimal string
      const pubkeyhashHex = Array.from(decoded.payload)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');

      return pubkeyhashHex;

    } catch (error) {
      console.error('Error decoding address:', error);
      toast.info('Error decoding address');
      return null;
    }
  }

//////////////////////////////////////////////////
///// Page Functions
//////////////////////////////////////////////////
const handleDateChange = (date: Date | null) => {
  setEndDate(date);
  handleEndBlockUpdate(date);
};

const handleEndBlockUpdate = (date: Date | null, initialHeight?: number) => {
  if (date) {
    const currentDate = new Date();   // Get the current date
    const differenceMs = date.getTime() - currentDate.getTime();  // Calculate the difference in milliseconds
    const differenceMinutes = Math.floor(differenceMs / (1000 * 60)); // Convert milliseconds to minutes
    const estimatedBlocks = Math.ceil(differenceMinutes / 10);  // Calculate the estimated number of blocks
    initialHeight ? 
      setEndingBlock(initialHeight + estimatedBlocks) : 
      setEndingBlock(blockHeight + estimatedBlocks);

  }
}

const handleImageSelect = () => {
  if (logo == ''){
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files![0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width <= 150 && img.height <= 150) {
            setLogo(e.target?.result as string);
          } else {
            console.log('Logo image must be 150x150 pixels or smaller.')
            toast.info('Error: Logo image must be 150x150 pixels or smaller.');
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    };
  }
};
const handleBannerSelect = () => {
  if (banner == ''){
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files![0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width <= 500 && img.height <= 120) {
            setBanner(e.target?.result as string);
          } else {
            console.log('Banner image must be 500x120 pixels or smaller.')
            toast.info('Error: Banner image must be 500x120 pixels or smaller.');
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    };
  }
};
const clearImage = () => {
  setLogo('');
};
const clearBanner = () => {
  setBanner('');
};
const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  if (e.target.value.length <= 300) {
    setShortDescription(e.target.value);
  }
};
const handleSetName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  if (e.target.value.length <= 50) {
    setName(e.target.value);
  }
};
const handleSetOwner = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  if (e.target.value.length <= 20) {
    setOwner(e.target.value);
  }
};

  return (
  <StyledCreate>
    <StyledBackground />
    <DatePickerStyles />

    <StyledTextSteps>
      <StyledTextBackground />
      <StyledWizard />

      <StyledNote>
        <StyledNoteTitle>NOTE</StyledNoteTitle>
        <StyledNoteText>
          <StyledTextCenter>
            <b>Launching a campaign costs <StyledBCHLogo size={20} />0.01<br/>
            Claiming a successful campaign costs 1.5% of the raised funds.</b>
          </StyledTextCenter>
        </StyledNoteText>
      </StyledNote>

      <StyledText><span style={{ color: '#0ac18e' }}>Step 1:</span> Connect your wallet</StyledText>
      <StyledSubText>
        Connect your wallet to this website with the 'Connect' button above. The wallet must support <a href="https://walletconnect.com/">walletconnect v2</a>,
        such as Paytaca, Zapit, and Cashonize. 
        <p/>
        <StyledLogos>
          <a href="https://www.paytaca.com/"><StyledLogo logoUrl={require('../../assets/img/paytaca.png')}/></a>
          <a href="https://zapit.io/"><StyledLogo logoUrl={require('../../assets/img/zapit.png')}/></a>
          <a href="https://cashonize.com/"><StyledLogo logoUrl={require('../../assets/img/cashonize.png')}/></a>
        </StyledLogos>
      </StyledSubText>

        <StyledNote>
          <StyledNoteTitle>NOTE</StyledNoteTitle>
          <StyledNoteText>
            The address you are currently connected with is <b><span style={{ color: '#0ac18e' }}>the only address</span></b> that can Claim your campaign.<br/>
            The address you are currently connected with is <b><span style={{ color: '#0ac18e' }}>where the BCH will be sent</span></b> when you Claim your succesful campaign.<br/>
            FundMe.Cash <b><span style={{ color: '#c60000' }}>never</span></b> has control over your funds, and <b><span style={{ color: '#c60000' }}>cannot</span></b> help you recover them.<br/>
            It is <b><span style={{ color: '#c60000' }}>your responsibility</span></b> to backup your wallet.<br/>
            <StyledWarning>Backup your wallet!</StyledWarning>
          </StyledNoteText>
        </StyledNote>

      <StyledText><span style={{ color: '#0ac18e' }}>Step 2:</span> Enter your Campaign details</StyledText>
        <StyledInputs>
          Campaign Ends:
          <DatePicker
            selected={endDate}
            onChange={handleDateChange}
            showTimeSelect
            dateFormat="MMMM d, yyyy, h:mm aa"
          />
        </StyledInputs>
        <StyledInputs>
          on block number: {blockHeight != 0 ? endingBlock : ''}
        </StyledInputs>
        
        <StyledInputs>
          Fundraising Amount <StyledBCHLogo size={26} />
          <input
            type="text"
            placeholder="123.45678900"
            value={fundTarget}
            onChange={(e) => handleSetFundTarget(e.target.value)}
          />
        </StyledInputs>

        <StyledNote>
            <StyledNoteTitle>NOTE</StyledNoteTitle>
            <StyledNoteText>Pledges can make your campaign go over 100% of the fundraising amount, so you can implement stretch goals yourself by setting targets in your campaign description and not claiming the camapign until those targets are reached.</StyledNoteText>
          </StyledNote>

        <Container1>
          <InputTitle
            placeholder="Campaign Title"
            value={name}
            onChange={handleSetName}
          />
          <InputOwner
            placeholder="Campaign Owner"
            value={owner}
            onChange={handleSetOwner}
          />
          {error && <ErrorText1>{error}</ErrorText1>}

          <StyledRow>
            <BannerPreview1 banner={banner} onClick={handleBannerSelect}>
              {!banner && <p>Click to select campaign banner (500x120)</p>}
              {banner && <ClearButton onClick={clearBanner}>×</ClearButton>}
            </BannerPreview1>
            <LogoPreview logo={logo} onClick={handleImageSelect}>
              {!logo && <p>Click to select your logo (150x150)</p>}
              {logo && <ClearButton onClick={clearImage}>×</ClearButton>}
            </LogoPreview>
          </StyledRow>
          <InputShortDescription
            placeholder="Campaign Short Description (max 300 characters)"
            value={shortDescription}
            onChange={handleDescriptionChange}
          />

          <StyledNote>
            <StyledNoteTitle>NOTE</StyledNoteTitle>
            <StyledNoteText>
              • Save a copy of your campaign descriptions in case any upload issues occur.<br />
              • It is required to provide a way to contact you in your description.
            </StyledNoteText>
          </StyledNote>

          <RichTextEditor isUpdate={false} value={description} onChange={setDescription} />
        </Container1>

      <StyledText><span style={{ color: '#0ac18e' }}>Step 3:</span> Click 'Create Campaign'</StyledText>
      <StyledSubText>
        This asks your wallet for approval to create the campaign on the CashStarter smart contract.
        It will cost a total of <StyledBCHLogo size={20} />0.01002 to create the campaign. The cost breaks down as:<br/>
        <StyledUL>
          <li>Creation Fee: <StyledBCHLogo size={20} />0.01 paid to FundMe.Cash</li>
          <li>Contract Fee: <StyledBCHLogo size={20} />0.00001 used to create the campaign UTXO</li>
          <li>Miner Fee: <StyledBCHLogo size={20} />0.00001</li>
        </StyledUL>
      </StyledSubText>
      <StyledButton onClick={handleCreateCampaign}>
        {txPending ? (
          <StyledButtonSpinner />
        ) : (
          'Create Campaign'
        )}
      </StyledButton>

      <StyledNote>
        <StyledNoteTitle>NOTE</StyledNoteTitle>
        <StyledNoteText><i>No compatible UTXO</i> error? Try consolidating your wallets BCH into one UTXO:</StyledNoteText>
        <StyledNoteButton onClick={handleConsolidateUtxos}>
          {txPending ? (
            <StyledButtonSpinner />
          ) : (
            'Consolidate'
          )}
        </StyledNoteButton>
      </StyledNote>

      <StyledText>Done!</StyledText>

      <StyledSubText>
        Your campaign has been submitted to the CashStarter contract! However, it won't appear on FundMe.Cash until it is reviewed and approved.
        <ul>
          <li>People can pledge and refund at any time while your campaign exists on the smart contract.</li>
          <li>You can claim the pledges, ending the campaign, at any time if the campaign reaches or exceeds the BCH goal you set.</li>
          <li>If the deadline is reached anyone can 'Stop' the campaign which will prevent any new pledges.</li>
          <li>You <b>can</b> still claim the funds even if the campaign has been 'Stopped', but only if the campaign still meets or exceeds the initial goal.</li>
        </ul>
      </StyledSubText>
    </StyledTextSteps>

  </StyledCreate>
  );
};

export default Create;
