import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { Utxo, Contract, TransactionBuilder, ElectrumNetworkProvider, Network } from 'cashscript';
import { AddressCashStarter, MasterCategoryID } from '../../constants/values'
import cashStarterPledge from '../../functions/cashstarterPledge';
import cashStarterRefund from '../../functions/cashstarterRefund';
import cashStarterClaim from '../../functions/cashstarterClaim';
import cashStarterStop from '../../functions/cashstarterStop';
import cashStarterCancel from '../../functions/cashstarterCancel';
import ArtifactCashStarter from '../../contracts/FundMeV8_Mainnet/json/CashStarter.json';
import ArtifactCashStarterRefund from '../../contracts/FundMeV8_Mainnet/json/CashStarterRefund.json';
import ArtifactCashStarterStop from '../../contracts/FundMeV8_Mainnet/json/CashStarterStop.json';
import ArtifactCashStarterClaim from '../../contracts/FundMeV8_Mainnet/json/CashStarterClaim.json';
import ArtifactCashStarterCancel from '../../contracts/FundMeV8_Mainnet/json/CashStarterCancel.json';
import { stringify } from '@bitauth/libauth';
import axios, { AxiosError } from 'axios';
import PledgeModal from '../../components/PledgeModal';
import ConsolidateModal from '../../components/ConsolidateModal';
import { environmentUrl } from '../../constants/environment';
import consolidateUTXOs from '../../functions/consolidateUTXOs';
import RichTextEditor from '../../components/RichTextEditor';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import {
  sheenAnimation,
  fillAnimation,
  PageContainer,
  StyledBreakline,
  StyledBackground,
  StyledBlack,
  StyledEnd,
  StyledEndBlock,
  CampaignNotFound,
  CampaignDetailContainer,
  StyledNavBar,
  StyledNavBarEntry,
  StyledNavBarEmpty,
  StyledSpacer,
  Column,
  Row,
  StyledRefundButtons,
  BannerRow,
  BannerImage,
  UserContainer,
  LogoImage,
  UserName,
  ProgressBar,
  StyledPledgeBar,
  DescriptionAndLinksContainer,
  StyledCampaignTitle,
  StyledTitleDiv,
  StyledDescription,
  StyledPledges,
  ProgressBarText,
  ProgressBarSubText,
  Progress,
  MenuButton,
  CancelButton,
  StyledPledgeAmount,
  StyledPledgeInput,
  StyledPledgeLogo,
  StyledNFT,
  StyledNFTTitle,
  StyledNFTInfo,
  StyledSatsInfo,
  StyledNFTText,
  StyledNFTTextRow,
  StyledSats,
  StyledNFTContainer,
  StyledRefreshButton,
  StyledFailButton,
  StyledCampaignID,
  Input,
  StyledDescriptionCenter,
  StyledSpinner,
  StyledButtonSpinner,
  StyledRowDetails,
  StyledRowPledge,
  StyledRowNFTs,
  StyledTitle,
  StyledDonation,
  StyledDonateName,
  StyledDonateAmount,
  StyledPledgeID,
  StyledDonateMessage,
  StyledDescriptionTabs,
  UpdateTextarea,
  CheckboxContainer,
  UrlInput,
  SubmitButton,
  UpdateBox,
  UpdateNumber,
  UpdateText
} from './CampaignDetail.styles'

interface Pledge {
  campaignID: string;
  pledgeID: string;
  name: string;
  message: string;
  amount: number;
}
interface Update {
  number: number;
  text: string;
}

interface CampaignUtxo extends Utxo {
  name: string;
  owner: string;
  description: string;
  logo: string;
  banner: string;
  pledges: Pledge[];
  ownersAddress: string;
  updates: Update[];
  isComplete: boolean;
}
interface SignMessageParams {
  message: string;
  userPrompt?: string;
}
interface SignTransactionParams {
  tx: string;
}
type NetworkType = "mainnet" | "testnet4" | "chipnet";

const CampaignDetail: React.FC = () => {
  const { id } = useParams(); // Get the campaign ID from the URL
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  const [campaignUTXO, setCampaignUTXO] = useState<Utxo>();
  const [campaignMap, setCampaignMap] = useState<Map<number, CampaignUtxo | null>>(new Map());
  const [contractsOK, setContractsOK] = useState(false);
  const [contractCashStarter, setContractCashStarter] = useState<Contract>();
  const [contractCashStarterRefund, setContractCashStarterRefund] = useState<Contract>();
  const [contractCashStarterStop, setContractCashStarterStop] = useState<Contract>();
  const [contractCashStarterClaim, setContractCashStarterClaim] = useState<Contract>();
  const [contractCashStarterCancel, setContractCashStarterCancel] = useState<Contract>();
  const [contractManager, setContractManager] = useState<Contract>();
  const [contractFailMinter, setContractFailMinter] = useState<Contract>();
  const [stringPledgeAmount, setStringPledgeAmount] = useState<string>("");
  const [selectedNFT, setSelectedNFT] = useState<Utxo | null>();
  const [nfts, setNFTs] = useState<Utxo[]>([]);
  const [isExpired, setIsExpired] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Overview');
  const [transactionBuilder, setTransactionBuilder] = useState<TransactionBuilder>();
  const [endDate, setEndDate] = useState('');
  const [endBlock, setEndBlock] = useState(0);
  const [campaignInfo, setCampaignInfo] = useState<CampaignUtxo>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [txPending, setTXPending] = useState(false);
  const [refundPending, setRefundPending] = useState(false);
  const [stopPending, setStopPending] = useState(false);
  const [cancelPending, setCancelPending] = useState(false);
  const [claimPending, setClaimPending] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pledgeDetails, setPledgeDetails] = useState({ name: '', message: '' });
  const [pledgeTotal, setPledgeTotal] = useState<number>(0);
  const [gotConsolidateError, setGotConsolidateError] = useState<boolean>(false);
  //update campaign info
  const [updateText, setUpdateText] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [urlAddress, setUrlAddress] = useState('');
  const MAX_CAMPAIGN_SIZE_MB = 25; // Maximum allowed size in MB

  //////////Compile an artifact into a CashScript Contract object
  async function compileContract(contractArtifact: any, args: any[]) {
    const contract = new Contract(contractArtifact, args, {provider: electrumServer, addressType: 'p2sh32'});
    return contract;
  }

  const hexToDecimal = (hex: string): number => {
    // Convert hex to big-endian and then to decimal
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };
  //Function to convert string to bigint
  function convertStringToBigInt(string: string): bigint {
    const bchAmount = parseFloat(string);                 //Parse the BCH string to a floating-point number
    const satoshis = Math.round(bchAmount * 100_000_000); //Convert BCH to satoshis and round the result to avoid precision issues
    return BigInt(satoshis);                              //Convert the satoshis to bigint for precise integer arithmetic
  }
  function toLittleEndianHex(value: any, byteCount: number) {
    let hex = (typeof value === 'bigint' ? value.toString(16) : Number(value).toString(16)); //Check number vs bigint and convert to hex accordingly
    hex = hex.padStart(byteCount * 2, '0'); // Pad with zeros to ensure correct byteCount
    return hex.match(/../g)?.reverse().join('') ?? '';  //Split into chunks of 2 (bytes), reverse (for little endian), and join back
  }
  const handleSelectTab = (tabName: string) => {
    setSelectedTab(tabName);
  };

  async function formatTime(blocks: number): Promise<string> {
    const blockHeight = await electrumServer.getBlockHeight();
    const blocksRemaining = Math.max(blocks - blockHeight, 0);

    if (blocksRemaining == 0) {
      return 'Expired';
    }

    const totalMinutes = blocksRemaining * 10;
    const totalHours = totalMinutes / 60;
    const remainingHours = totalHours % 24;
    const days = Math.floor(totalHours / 24);
    const hours = Math.floor(totalHours % 24);
    const minutes = Math.floor((remainingHours - hours) * 60);

    let endDate = '';
    if (days > 0) {
      endDate += `${days}d `;
    }
    if (hours > 0 || days > 0) { // Include hours if there are any days
      endDate += `${hours}h `;
    }
    endDate += `${minutes}m`;

    return endDate;
  }

  const formatSatoshiToBCH = (satoshis: bigint) => {
    const bch = Number(satoshis) / 100000000;
    return bch.toFixed(8);
  };
  
  async function signMessage({ message, userPrompt }: SignMessageParams): Promise<string | undefined> {
    const options = {
        message: message,
        userPrompt: userPrompt
      };

    console.log('signing message...');
    try {
        if (walletConnectInstance) {
        //const params = JSON.parse(stringify(options));
        
        console.log('wc params:');
        console.log(options);
        const result = await walletConnectInstance.request({
            chainId: connectedChain,
            topic: walletConnectSession.topic,
            request: {
                method: "bch_signMessage",
                params: options,
            },
        });
        console.log('signMessage result: ');
        console.log(result);
        return result;
        }
    } catch (error) {
        console.log('signMessage error: ' + error);
        return undefined;
    }
}

async function signTransaction(options: any) {
  console.log('signing transaction...');
  try {
      if (walletConnectInstance) {
      const params = JSON.parse(stringify(options));
      console.log('wc params:');
      console.log(params);
      toast.info(`Requesting signature from your wallet...`);
      const result = await walletConnectInstance.request({
          chainId: connectedChain,
          topic: walletConnectSession.topic,
          request: {
          method: "bch_signTransaction",
          params: params,
          },
      });
      return result;
      }
  } catch (error) {
      console.log('signTransation error: ' + error);
      return undefined;
  }
}

  // Function to take users pledge string, verify only one decimal, remove all but numbers, set state
  const handleSetPledgeAmount = (value: string) => {
    let newValue = value.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, '$1'); // Allow numbers and a single decimal point

    // Check if there's a decimal point, and if so, limit the length after it to 8
    const decimalIndex = newValue.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = newValue.substring(0, decimalIndex);
      const decimalPart = newValue.substring(decimalIndex + 1, decimalIndex + 9); // Grab up to 8 decimal places
      newValue = `${integerPart}.${decimalPart}`;
    }

    setStringPledgeAmount(newValue !== "" ? newValue : "");
  }

  const handlePledgeModal = () => {
    setIsModalOpen(true);
  };

// Function to forward users pledge to the contract function
const handlePledge = async (name: string, message: string) => {
  setTXPending(true);

  if (electrumServer && usersAddress && campaignUTXO) {
    const campaignID = campaignUTXO.token?.nft?.commitment.substring(70, 80) ?? "0";
    const pledgeID = campaignUTXO.token?.nft?.commitment.substring(62, 70) ?? "0";;
    const pledgeAmount = convertStringToBigInt(stringPledgeAmount);
    console.log('Pledge details:', { campaignID, pledgeID, name, message, pledgeAmount });

    if (name == '') {
      name = 'Anonymous';
    }
    if (message == '') {
      message = ' ';
    }

    const signResult = await cashStarterPledge({electrumServer, usersAddress, contractCashStarter, campaignID, pledgeID, pledgeAmount, signTransaction, setError: (msg: string) => toast.info(msg), setGotConsolidateError });

    if (signResult != undefined) {
      const rawTx = signResult.signedTransaction;
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      try {
        const result = await electrumServer.sendRawTransaction(rawTx);
        toast.info(`Pledge sent! TxID:\n${result}`);
        console.log('Broadcasted, txid: ' + result);

        const decimalCampaignID = hexToDecimal(campaignID);
        const decimalPledgeID = hexToDecimal(pledgeID) + 1; //pledgeID is the current pledge# on the campaignUTXO, new pledge adds 1 to it during pledging

        // Save to external server
        await axios.post(environmentUrl + '/save-pledge', { campaignID: decimalCampaignID, pledgeID: decimalPledgeID, name, message, amount: stringPledgeAmount });
        console.log('Pledge sent! TxID: ', result);

        // Update server stats with the pledge
        try {
          axios.post(environmentUrl + '/update-totalPledges', {
            txid: result
          });
        } catch (error) {
          console.error('Error updating totalPledges:', error);
        }

      } catch (error) {
        console.error('Error pledging:', error);
        toast.info(`Error pledging: \n${error}`);
      }
    }

  } else {
    console.log('Error pledging. Is your wallet connected?');
    toast.info('Error pledging. Is your wallet connected?');
  }

  setTXPending(false);
}

// Function to forward users pledge to the contract function
const handleClaim = async (campaignID: string) => {
  console.log('handleClaim camapignID: ', campaignID);
  setClaimPending(true);

  if (electrumServer && campaignID && usersAddress != '') {
    const signResult = await cashStarterClaim({electrumServer, usersAddress, contractCashStarter, contractCashStarterClaim, campaignID, signTransaction, setError: (msg: string) => toast.info(msg)});
    if (signResult != undefined) {
      const rawTx = signResult.signedTransaction;
      //const rawTx = signResult;
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      electrumServer.sendRawTransaction(rawTx).then((result: string) => {
        console.log('Broadcasted, txid: ' + result);
        toast.info(`Claimed! TxID: \n${result}`);

        // Update server stats with the raised BCH
        try {
          const raisedBCH = Number(campaignUTXO?.satoshis) / 100000000;
          axios.post(environmentUrl + '/update-stats', {
            raisedBCH: raisedBCH,
            txid: result
          });
        } catch (statsError) {
          console.error('Error updating stats:', statsError);
        }

      }).catch((error: Error) => {
        toast.info(`Error claiming: \n${error}`);
        console.error('Error claiming:', error);
      });
    }
  } else {
    toast.info(`Error claiming. Is the correct wallet connected?`);
    console.log('Error claiming. Is the correct wallet connected?');
  }

  setClaimPending(false);
}

// Function to cancel 
const handleCancel = async (campaignID: string) => {
  console.log('handleClaim camapignID: ', campaignID);
  setCancelPending(true);

  if (electrumServer && campaignID && usersAddress != '') {
    const signResult = await cashStarterCancel({electrumServer, usersAddress, contractCashStarter, contractCashStarterCancel, campaignID, signTransaction, setError: (msg: string) => toast.info(msg)});
    if (signResult != undefined) {
      const rawTx = signResult.signedTransaction;
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      // Ask user for confirmation before broadcasting
      if (window.confirm("Are you absolutely sure you want to cancel?")) {
        // User clicked OK
        electrumServer.sendRawTransaction(rawTx).then((result: string) => {
          console.log('Broadcasted, txid: ' + result);
          toast.info(`Campaign canceled. TxID:\n${result}`);
        }).catch((error: Error) => {
          console.error('Error canceling:', error);
          toast.info(`Error canceling:\n${error}`);
        });
      } else {
        // User clicked Cancel
        toast.info('Cancel not submitted');
        console.log('Cancel not submitted');
      }
    }
  } else {
    console.log('Error. Is your wallet connected?');
    toast.info(`Error. Is your wallet connected?`);
  }

  setCancelPending(false);
}

// Function to forward users pledge to the contract function
const handleRefund = async (campaignID: string, selectedNFT: Utxo) => {
  console.log('handleRefund camapignID: ', campaignID);
  setRefundPending(true);

  if (electrumServer && campaignID && selectedNFT) {
    const campaignID = selectedNFT.token?.nft?.commitment.substring(70, 80) ?? "0";
    const pledgeID = selectedNFT.token?.nft?.commitment.substring(62, 70) ?? "0";

    const signResult = await cashStarterRefund({electrumServer, usersAddress, contractCashStarter, contractCashStarterRefund, campaignID, selectedNFT, signTransaction, setError: (msg: string) => toast.info(msg)});
    if (signResult != undefined) {
      const rawTx = signResult.signedTransaction;
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      try {
        const result = await electrumServer.sendRawTransaction(rawTx);
        console.log('Broadcasted, txid: ' + result);

        const decimalCampaignID = hexToDecimal(campaignID);
        const decimalPledgeID = hexToDecimal(pledgeID);

        // Send pledge deletion notice to external server
        await axios.delete(environmentUrl + '/delete-pledge', { data: { campaignID: decimalCampaignID, pledgeID: decimalPledgeID, txid: result } });
        toast.info(`Refund submitted. TxID:\n${result}`);
      } catch (error) {
        console.error('Error refunding:', error);
        toast.info(`Error refunding: \n${error}`);
      }
    }

  } else {
    console.log('Error refunding. Did you select the correct PledgeNFT?');
    toast.info(`Error refunding. Did you select the correct PledgeNFT?`);
  }

  setRefundPending(false);

}

// Function to forward users pledge to the contract function
const handleStop = async (campaignID: string) => {
  console.log('handleStop camapignID: ', campaignID);
  setStopPending(true);

  if (electrumServer && campaignID) {

    const signResult = await cashStarterStop({electrumServer, contractCashStarter, contractCashStarterStop, campaignID });
    if (signResult != undefined) {
      //const rawTx = signResult.signedTransaction; //using walletConnect
      const rawTx = signResult; //anyone can spend
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      electrumServer.sendRawTransaction(rawTx).then((result: string) => {
          console.log('Broadcasted, txid: ' + result);
          toast.info(`Campaign stopped. TxID:\n${result}`);
      }).catch((error: Error) => {
          console.error('Error stopping campaign:', error);
          toast.info(`Error stopping campaign: \n${error}`);
      });

    }
  } else {
    console.log('Error stopping campaign. Is your wallet connected?');
    toast.info(`Error stopping campaign. Is your wallet connected?`);
  }

  setStopPending(false);
}

async function handleConsolidateUtxos() {
  setTXPending(true);

  if (electrumServer && usersAddress && transactionBuilder) { 
    const signResult = await consolidateUTXOs({electrumServer, usersAddress, transactionBuilder, signTransaction, setError: (msg: string) => toast.info(msg) });
    const rawTx = signResult.signedTransaction; 
    console.log('signedTransaction from walletconnect: ');
    console.log(signResult);
    
    electrumServer.sendRawTransaction(rawTx).then((result: string) => {
      toast.info(`Consolidated. TxID:\n${result}`);
      console.log('Broadcasted, txid: ' + result);
    }).catch((error: Error) => {
      toast.info(`Error consolidating: \n${error}`);
      console.log('Error consolidating: ', error);
    });

  } else {
    console.log('Error consolidating, connect wallet or refresh?');
    toast.info('Error consolidating, connect wallet or refresh?');
  }
  
  setTXPending(false);
}

  useEffect(() => {
    //console.log('1. useEffect called');
    async function checkAndCompileContracts() {
      //console.log('2. compiling contracts...');
      if (contractsOK === false && electrumServer) {
        try {
          const compiledCashStarter = await compileContract(ArtifactCashStarter, []);
          setContractCashStarter(compiledCashStarter);
          //const compiledFailMinter = await compileContract(ArtifactFailMinter, []);
          const compiledCashStarterRefund = await compileContract(ArtifactCashStarterRefund, []);
          setContractCashStarterRefund(compiledCashStarterRefund);
          const compiledCashStarterStop = await compileContract(ArtifactCashStarterStop, []);
          setContractCashStarterStop(compiledCashStarterStop);
          const compiledCashStarterClaim = await compileContract(ArtifactCashStarterClaim, []);
          setContractCashStarterClaim(compiledCashStarterClaim);
          const compiledCashStarterCancel = await compileContract(ArtifactCashStarterCancel, []);
          setContractCashStarterCancel(compiledCashStarterCancel);

          //setContractFailMinter(compiledFailMinter);
          setContractsOK(true);
          console.log('3. contracts OK');
        } catch (error) {
          console.log('contract compiling error: ' + error);
        }
      } else {
        console.log('electrumServer is not ready yet');
      }
    }
  
    checkAndCompileContracts();

    if (!transactionBuilder) {
      const provider = new ElectrumNetworkProvider(Network.MAINNET);
      //const provider = new ElectrumNetworkProvider(Network.CHIPNET);
      setTransactionBuilder(new TransactionBuilder({ provider }));
    }

  }, [electrumServer]);

  useEffect(() => {
    async function getCampaign() {
      if (!electrumServer) return;
      console.log('electrumServer detected');

      setIsLoading(true);  //starts loading spinner graphic

      //fetch campaign metadata from server       
      let response;
      try {   
        response = await axios.get(environmentUrl + `/get-campaign/` + id);
        const campaignInfo = response.data;
        setCampaignInfo(campaignInfo);
        const pledgeTotal = campaignInfo.pledges.reduce((sum: number, pledge: any) => sum + Number(pledge.amount), 0);
        setPledgeTotal(pledgeTotal);
        
      } catch (err) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 404) {
          setFetchError(true);
        } else {
          setFetchError(true);
          console.log('fetch unknown error: ', err);
        }
        setIsLoading(false);
        return;
      }


      //delay to allow electrum to stabilize
      setTimeout(async () => {
        const cashStarterUTXOs: Utxo[] = await electrumServer.getUtxos(AddressCashStarter);
        let campaignUTXO = cashStarterUTXOs.find( 
          utxo => utxo.token?.category == MasterCategoryID //only CashStarter NFT's
            && utxo.token?.nft?.capability == 'minting' //only minting ones
            && utxo.token.nft?.commitment.substring(70, 80) != 'ffffffffff' //not the masterNFT
            && utxo.token.nft?.commitment.substring(70, 80) == toLittleEndianHex(id, 5) //this campaign id
        );

        if (!campaignUTXO) {  //no utxo found, could be failed already
          campaignUTXO = cashStarterUTXOs.find( 
            utxo => utxo.token?.category == MasterCategoryID  //only CashStarter NFT's
              && utxo.token?.nft?.capability == 'mutable'        //only fail()'d campaigns
              && utxo.token.nft?.commitment.substring(70, 80) != 'ffffffffff' //not the masterNFT
              && utxo.token.nft?.commitment.substring(70, 80) == toLittleEndianHex(id, 5) //this specific id
          );
        }
        setCampaignUTXO(campaignUTXO);  //save found campaign

        if (campaignUTXO) { //if an active or fail'd campaign was found
          const tempCampaignMap = new Map<number, CampaignUtxo>(); //Temporary map to populate with CampaignUtxo entries
          const capability = campaignUTXO.token?.nft?.capability;
          const campaignId = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0");
          const endBlock = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(52, 60) ?? "0");
          setEndBlock(endBlock);
          const endDate = await formatTime(endBlock);

          //if campaign has already been fail()'d, disables Fail button interaction
          if (capability == 'mutable') {
            setIsFailed(true);
            setIsExpired(true);
            setEndDate(endBlock.toString());

          //if campaign is still active, or not yet fail'd
          } else if (capability == 'minting') {
            const campaignId = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0");
            const endBlock = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(52, 60) ?? "0");
            const blockHeight = await electrumServer.getBlockHeight();

            //set whether campaign has passed its expiry block, enables Fail button
            if (blockHeight >= endBlock) {
              setIsExpired(true);
            }
            
              //set map data with block it will be endable at
              {/*
              tempCampaignMap.set(campaignId, {
                ...campaignUTXO,
                name: campaignInfo.name,
                owner: campaignInfo.owner,
                description: campaignInfo.description,
                banner: campaignInfo.banner,
                logo: campaignInfo.logo,
                endDate: endDate
              });
            */}
            setEndDate(endDate);
            //setCampaignMap(tempCampaignMap);
          }

        //else no UTXO was found but its campaignInfo was, campaign was claimed
        } else if (!campaignUTXO && campaignInfo) {
          setIsClaimed(true);
        }

        setIsLoading(false);
      }, 2000); // SetTimeout delay of 2 seconds
    }

    getCampaign();
  }, [electrumServer, id]);

// Get refund NFT's for listing so user can select one
async function fetchReceiptNFTs() {
  if (electrumServer && usersAddress != '') {
    try {
      const utxos: Utxo[] = await electrumServer.getUtxos(usersAddress);

      const filteredNFTs: Utxo[] = utxos.filter(utxo => 
        utxo.token?.category == MasterCategoryID
        && utxo.token?.nft?.commitment.substring(70,80) === toLittleEndianHex(id, 5) // Filter to only this campaign ID
        && utxo.token?.nft?.capability == 'none')     // Filter to only receiptNFTs
      setNFTs(filteredNFTs);
      console.log('set NFTs: ', filteredNFTs);
    } catch (error) {
      console.error('Error fetching UTXOs:', error);
    }
  }
};

////////// Campaign owner posts an update //////////
const handleSubmitUpdate = async () => {
  // Check the size of the campaign data
  const update = JSON.stringify({
    campaignID: id,
    updateText,
    isComplete,
    urlAddress,
    usersAddress
  });
  const updateSizeMB = Buffer.byteLength(update, 'utf8') / (1024 * 1024);

  if (updateSizeMB > MAX_CAMPAIGN_SIZE_MB) {
    toast.info(`Error: Campaign size (${updateSizeMB.toFixed(2)} MB) exceeds max of (${MAX_CAMPAIGN_SIZE_MB} MB)`);
    return;
  }

  try {
    const response = await axios.post(environmentUrl + '/update-campaign', {
      campaignID: id, // Assuming you have access to the campaign ID
      updateText,
      isComplete,
      urlAddress,
      usersAddress
    });
    if (response.status === 200) {
      // Clear the inputs after successful submission
      setUpdateText('');
      setIsComplete(false);
      setUrlAddress('');

      toast.info('Update submitted');
    }

  } catch (error) {
    toast.info('Error submitting update, check console');
    console.error('Error updating campaign:', error);
  }
};

//////////////////////////////////////////////////
////////// Return PledgeNFT UTXO
//////////////////////////////////////////////////
const NFTItem: React.FC<{ utxo: Utxo }> = ({ utxo }) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);

  function handleSetSelectedNFT() {
    setIsSelected(current => !current);
    setSelectedNFT(utxo)
  }
  return (
    <StyledNFT onClick={handleSetSelectedNFT} isSelected={isSelected}>
      <StyledNFTTitle>
        Pledge #{hexToDecimal(utxo.token?.nft?.commitment.substring(62, 70) ?? "0")}
      </StyledNFTTitle>
      <StyledSats>
        <StyledPledgeLogo size={20}/> {formatSatoshiToBCH(BigInt(hexToDecimal(utxo.token?.nft?.commitment.substring(0, 11) ?? "0")))}
      </StyledSats>
    </StyledNFT>
  );
};

  return (
    <PageContainer>
      <StyledBackground />

      <PledgeModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onSubmit={handlePledge}
        pledgeAmount={stringPledgeAmount}
      />

      {gotConsolidateError &&
        <ConsolidateModal
          onRequestClose={() => setGotConsolidateError(false)}
          onSubmit={handleConsolidateUtxos}
          stringPledgeAmount={stringPledgeAmount}
        />
      }

    {/*  {[...campaignMap.values()].map((campaign, index) => (
        campaign && (
        */}

        {/* Campaign has a UTXO and campaign data exists on server */}
        {campaignUTXO && campaignInfo && 
            <CampaignDetailContainer>

              <Column>
                <BannerImage logoUrl={campaignInfo.banner} />
                <BannerRow>
                  <UserContainer>
                    {isLoading == true &&
                      <StyledSpinner />
                    }
                    <LogoImage logoUrl={campaignInfo.logo} />
                    <UserName>{campaignInfo.owner}</UserName>
                  </UserContainer>
                </BannerRow>
              </Column>

              <StyledTitleDiv>{campaignInfo.name}</StyledTitleDiv>

              <ProgressBar title="Click to set remaining amount" onClick={() => {
                if (campaignUTXO) {
                  const currentAmount = Number(campaignUTXO.satoshis) / 100000000;
                  const targetAmount = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000;
                  const remainingAmount = (targetAmount - currentAmount).toFixed(8);
                  handleSetPledgeAmount(remainingAmount);
                }
              }}>
                <Progress progress={(Number(campaignUTXO.satoshis) / hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100} />
                <ProgressBarText>{((Number(campaignUTXO.satoshis) / hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100).toFixed(2)}%</ProgressBarText>
                <ProgressBarSubText>{(Number(campaignUTXO.satoshis) / 100000000 )} / {(hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000)}</ProgressBarSubText>
                {!isFailed && !isClaimed && isExpired && <StyledFailButton width={0} onClick={(e) => {
                  e.stopPropagation(); // Prevent the ProgressBar click from triggering
                  handleStop(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")
                }}>Stop</StyledFailButton>}
              </ProgressBar>

              <StyledPledgeBar>
                <StyledRowDetails>
                  <StyledBlack />
                  <StyledCampaignID><b>Campaign:</b>&nbsp;#{hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}</StyledCampaignID>
                  {campaignUTXO.token?.nft?.capability == 'mutable' ? 
                    <StyledEnd pledgedAmount={0}>Campaign Stopped</StyledEnd>
                    :
                    <StyledEnd pledgedAmount={1}>
                      <b>Ends:</b>&nbsp;{endDate}
                    </StyledEnd>
                  }

                  <StyledEndBlock><b>End Block:</b>&nbsp;{endBlock}</StyledEndBlock>
                </StyledRowDetails>

                <StyledRowPledge>
                  <StyledBlack />
                  <StyledTitle>Make a Pledge</StyledTitle>
                  <StyledSpacer height={10} />
                  <Column>
                    <StyledPledgeLogo size={40}/>
                    <StyledPledgeInput
                      type="text"
                      placeholder="0.0001"
                      value={stringPledgeAmount}
                      onChange={(e) => handleSetPledgeAmount(e.target.value)}
                    />
                  </Column>
                  <MenuButton disabled={isFailed || isClaimed} width={150} onClick={() => handlePledgeModal()}>
                    {txPending ? (
                      <StyledButtonSpinner />
                    ) : (
                      'Pledge'
                    )}  
                  </MenuButton>
                </StyledRowPledge>

                <StyledRowNFTs>
                  <StyledBlack />
                  <StyledTitle>Cancel your Pledge</StyledTitle>
                  <StyledRefundButtons>
                    <StyledRefreshButton onClick={fetchReceiptNFTs} width={0}>Refresh</StyledRefreshButton>
                    <MenuButton disabled={!selectedNFT} width={90} onClick={() => handleRefund(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0", selectedNFT!)}>
                      {refundPending ? (
                        <StyledButtonSpinner />
                      ) : (
                        'Refund'
                      )}
                    </MenuButton>
                  </StyledRefundButtons>
                    
                  <StyledNFTContainer>
                    {nfts.map((utxo, index) => (
                      <NFTItem key={index} utxo={utxo} />
                    ))}
                  </StyledNFTContainer>

                </StyledRowNFTs>
              </StyledPledgeBar>

              <StyledNavBar>
                <StyledNavBarEntry isSelected={selectedTab === 'Overview'} onClick={() => handleSelectTab('Overview')}>Overview</StyledNavBarEntry>
                <StyledNavBarEntry isSelected={selectedTab === 'Updates'} onClick={() => handleSelectTab('Updates')}>Updates</StyledNavBarEntry>
                {campaignInfo.ownersAddress == usersAddress ?
                  <StyledNavBarEntry isSelected={selectedTab === 'Claim'} onClick={() => handleSelectTab('Claim')}>Claim</StyledNavBarEntry>
                  : <StyledNavBarEmpty />
                }
                </StyledNavBar>

              <DescriptionAndLinksContainer>
                <StyledDescriptionTabs>
                  {selectedTab === 'Overview' && (
                    <StyledDescription dangerouslySetInnerHTML={{ __html: campaignInfo.description }} />
                  )}
                  {selectedTab === 'Updates' && (
                    <StyledDescription>
                      <StyledDescriptionCenter>
                        {usersAddress == campaignInfo.ownersAddress &&
                          <>
                            <RichTextEditor isUpdate={true} value={updateText} onChange={setUpdateText} />
                            <SubmitButton onClick={handleSubmitUpdate}>Submit Update</SubmitButton>
                          </>
                        }
                      
                      {campaignInfo?.updates.length > 0 ? (
                        campaignInfo?.updates.map((update: any) => (
                          <UpdateBox key={update.number}>
                            <UpdateNumber>Update #{update.number}</UpdateNumber>
                            <UpdateText dangerouslySetInnerHTML={{ __html: update.text }} />
                          </UpdateBox>
                        ))
                      ) : (
                        'No updates have been posted by the creator yet.'
                      )}

                      </StyledDescriptionCenter>
                    </StyledDescription>
                  )}
                  {selectedTab === 'Claim' && (
                    <StyledDescription>
                      <StyledDescriptionCenter>
                        <MenuButton disabled={(Number(campaignUTXO.satoshis)) < (hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0"))} width={150} onClick={() => handleClaim(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}>
                          {claimPending ? (
                            <StyledButtonSpinner />
                          ) : (
                            'Claim'
                          )}  
                        </MenuButton>
                        Only the address that created the campaign can Claim the campaign. <br /> 
                        The creator can claim the raised funds at any time when they are 100% or above the initial funding target.
                        <p/>
                        <CancelButton disabled={campaignUTXO.token?.nft?.capability != 'minting'} onClick={() => handleCancel(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}>
                        {cancelPending ? (
                          <StyledButtonSpinner />
                        ) : (
                          'Cancel'
                        )}  
                        </CancelButton>
                        Cancel an active campaign, preventing new pledges.<br/>
                        Only the address that created the campaign can Cancel the campaign.
                      </StyledDescriptionCenter>
                    </StyledDescription>
                  )}
                </StyledDescriptionTabs>

                <StyledPledges>
                  <StyledBlack />
                  <StyledTitle>Pledges</StyledTitle>
                  {!campaignInfo && <StyledButtonSpinner />}
                  <StyledSpacer height={20} />

                  {campaignInfo.pledges.map((pledge, index) => (
                    <StyledDonation key={index}>
                      <StyledDonateName nameLength={pledge.name.length}>
                        {pledge.name}
                      </StyledDonateName>
                      <StyledDonateMessage><i>"{pledge.message}"</i></StyledDonateMessage>
                      <StyledDonateAmount>
                        <StyledPledgeLogo size={20}/>
                        {pledge.amount}
                      </StyledDonateAmount>
                      <StyledPledgeID>#{pledge.pledgeID}</StyledPledgeID>
                    </StyledDonation>
                  ))}
                </StyledPledges>

              </DescriptionAndLinksContainer>
            </CampaignDetailContainer>
          }

          {/* Campaign does not have a UTXO but campaign data exists on server */}
          {!campaignUTXO && campaignInfo && 
            <CampaignDetailContainer>

              <Column>
                <BannerImage logoUrl={campaignInfo.banner} />
                <BannerRow>
                  <UserContainer>
                    {isLoading == true &&
                      <StyledSpinner />
                    }
                    <LogoImage logoUrl={campaignInfo.logo} />
                    <UserName>{campaignInfo.owner}</UserName>
                  </UserContainer>
                </BannerRow>
              </Column>

              <StyledTitleDiv>{campaignInfo.name}</StyledTitleDiv>
              <ProgressBar>
                <ProgressBarText>{pledgeTotal}</ProgressBarText>

                <Progress progress={100} />
              {/*
                <ProgressBarSubText>{(Number(campaignUTXO.satoshis) / 100000000 )} / {(hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000)}</ProgressBarSubText> 
                {!isFailed && !isClaimed && !isExpired && <StyledFailButton onClick={() => handleFail(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}>Stop</StyledFailButton>}
              */}
              </ProgressBar>

              <StyledPledgeBar>
                <StyledRowDetails>
                  <StyledBlack />
                  <StyledCampaignID><b>Campaign:</b>&nbsp;#{id}</StyledCampaignID>
                  {!isLoading &&
                    <>
                      <StyledEnd pledgedAmount={pledgeTotal}>{campaignInfo.pledges.length > 0 && !isFailed ? 'Campaign Successful' : 'Campaign Failed'}</StyledEnd>
                      {endBlock != 0 && 
                        <StyledEndBlock><b>End Block:</b>&nbsp;{endBlock}</StyledEndBlock>
                      }
                    </>
                  }
                </StyledRowDetails>

                <StyledRowPledge>
                  <StyledBlack />
                  <StyledTitle>Make a Pledge</StyledTitle>
                  <StyledSpacer height={15} />
                  <Column>
                    <StyledPledgeLogo size={40}/>
                    <StyledPledgeInput
                      type="text"
                      placeholder="Amount"
                      value={stringPledgeAmount}
                      onChange={(e) => handleSetPledgeAmount(e.target.value)}
                    />
                  </Column>
                  {/* <MenuButton disabled={true} width={150}>Pledge</MenuButton> */}
                </StyledRowPledge>

                <StyledRowNFTs>
                  <StyledBlack />
                  <StyledTitle>See your Pledge</StyledTitle>
                  <StyledRefundButtons>
                    <StyledRefreshButton onClick={fetchReceiptNFTs} width={0}>Refresh</StyledRefreshButton>
                    {/*
                    <MenuButton disabled={!selectedNFT} width={90} onClick={() => handleRefund(toLittleEndianHex(id, 5), selectedNFT!)}>
                      {refundPending ? (
                        <StyledButtonSpinner />
                      ) : (
                        'Refund'
                      )}
                    </MenuButton>
                    */}
                  </StyledRefundButtons>
                    
                  <StyledNFTContainer>
                    {nfts.map((utxo, index) => (
                      <NFTItem key={index} utxo={utxo} />
                    ))}
                  </StyledNFTContainer>

                </StyledRowNFTs>
              </StyledPledgeBar>

              <StyledNavBar>
                <StyledNavBarEntry isSelected={selectedTab === 'Overview'} onClick={() => handleSelectTab('Overview')}>Overview</StyledNavBarEntry>
                <StyledNavBarEntry isSelected={selectedTab === 'Updates'} onClick={() => handleSelectTab('Updates')}>Updates</StyledNavBarEntry>
                <StyledNavBarEmpty />
              </StyledNavBar>

              <DescriptionAndLinksContainer>
                <StyledDescriptionTabs>
                  {selectedTab === 'Overview' && (
                    <StyledDescription dangerouslySetInnerHTML={{ __html: campaignInfo.description }} />
                  )}
                  {selectedTab === 'Updates' && (
                    <StyledDescription>
                      <StyledDescriptionCenter>
                        {usersAddress == campaignInfo.ownersAddress &&
                          <>
                            {campaignInfo.isComplete != true &&
                              <>
                                <CheckboxContainer>
                                  <input 
                                    type="checkbox" 
                                    checked={isComplete}
                                    onChange={(e) => setIsComplete(e.target.checked)}
                                  />
                                  <label>The campaign is complete</label>
                                </CheckboxContainer>
                                <UrlInput 
                                  type="text"
                                  value={urlAddress}
                                  onChange={(e) => setUrlAddress(e.target.value)}
                                  placeholder="Enter URL address..."
                                />
                              </>
                            }

                            {usersAddress == campaignInfo.ownersAddress &&
                              <>
                                <RichTextEditor isUpdate={true} value={updateText} onChange={setUpdateText} />
                                <SubmitButton onClick={handleSubmitUpdate}>Submit Update</SubmitButton>
                              </>
                            }
                          </>
                        }
                      
                      {campaignInfo?.updates.length > 0 ? (
                        campaignInfo?.updates.map((update: any) => (
                          <UpdateBox key={update.number}>
                            <UpdateNumber>Update #{update.number}</UpdateNumber>
                            <UpdateText dangerouslySetInnerHTML={{ __html: update.text }} />
                          </UpdateBox>
                        ))
                      ) : (
                        'No updates have been posted by the creator yet.'
                      )}

                      </StyledDescriptionCenter>
                    </StyledDescription>
                  )}
                </StyledDescriptionTabs>

                <StyledPledges>
                  <StyledBlack />
                  <StyledTitle>Pledges</StyledTitle>
                  {!campaignInfo && <StyledButtonSpinner />}
                  <StyledSpacer height={20} />

                  {campaignInfo.pledges.map((pledge, index) => (
                    <StyledDonation key={index}>
                      <StyledDonateName nameLength={pledge.name.length}>
                        {pledge.name}
                      </StyledDonateName>
                      <StyledDonateMessage><i>{pledge.message}</i></StyledDonateMessage>
                      <StyledDonateAmount>
                        <StyledPledgeLogo size={20}/>
                        {pledge.amount}
                      </StyledDonateAmount>
                      <StyledPledgeID>#{pledge.pledgeID}</StyledPledgeID>
                    </StyledDonation>
                  ))}
                </StyledPledges>

              </DescriptionAndLinksContainer>
            </CampaignDetailContainer>
          }

      {fetchError && 
        <CampaignNotFound>
          Details for campaign #{id} were not found on the server
        </CampaignNotFound>
      }

    </PageContainer>
  );
}

export default CampaignDetail;