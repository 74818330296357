import styled, { createGlobalStyle } from 'styled-components';
import CasualLogo from '../../assets/img/casualLogo.png';
import ImageWizard from '../../assets/img/wizard.png';
import BCHLogo from '../../assets/img/bch.png';
import Spinner from '../../assets/img/spinner.gif';

interface StyledButtonProps {
    isactive?: boolean;
  }
  interface LogoDivProps {
    logoUrl: string;
  }
  interface StyledLogoProps {
    size: number;
  }
  
  export const StyledCreate = styled.div`
    color: #fff; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background: #000;
    border: 0px solid red;
    a {
      color: #0AC18E;
      &:hover {
        color: #09af81;
      }
  `;
  export const StyledBackground = styled.div`
    background-image: url(${CasualLogo});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    display: flex;
    height: 100vh;
    width: 100%;
    border: 0px solid red;
    z-index: 0;
    opacity: 0.4;
  `;
  export const StyledWizard = styled.div`
    background-image: url(${ImageWizard});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -37px;
    right: 0px;
    display: flex;
    height: 500px;
    width: 332px;
    border: 0px solid red;
    z-index: 0;
    opacity: 0.2;
  `;
  export const StyledTextSteps = styled.div`
  background-color: transparent;
  border: 1px solid #0AC18E;
  border-top: 0px;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  margin: 1px auto;
  padding: 20px;
  color: white;
  height: 100%;
  width: 50%;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
    font-weight: 500;
  @media (max-width: 1500px) {
    width: 80%;
  }
  `;
  export const StyledTextBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.75;
  z-index: 0;
  `;
  export const StyledText = styled.h1`
  text-decoration: none;
  font-size: 22px;
  color: #fff;
  border-bottom: 1px solid #0AC18E;
  z-index: 1;
  `;
  export const StyledTextCenter = styled.div`
  text-align: center;
  `;
  export const StyledWarning = styled.div`
  color: #c60000;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  `;
  export const StyledSubText = styled.div`
  position: relative;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  top: -10px;
  border: 0px solid pink;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-left: 10px;
  font-weight: 500;
  `;
  export const StyledNoteTitle = styled.div`
  position: absolute;
  width: 100%;
  height: 15px;
  background: #0AC18E;
  font-size: 11px;
  text-decoration: none;
  color: #fff;
  top: 0px;
  text-align: left;
  font-weight: 700;
  padding-left: 20px;
  `;
  export const StyledNoteText = styled.div`
  position: relative;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  top: 0px;
  border: 0px solid pink;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-weight: 400;
  margin-top: 10px;
  `;
  export const StyledNote = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: #002017;
  top: 10px;
  border: 2px outset #0AC18E;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  gap: 5px;
  overflow: hidden;
  `;
  export const StyledInputs = styled.div`
  background-color: black;
  border: 0px solid #00cc66;
  display: flex;
  flex-direction: row;
  margin: 1px auto;
  padding: 1px;
  color: white;
  height: 100%;
  width: 50%;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  `;
  export const StyledRow = styled.div`
  background-color: black;
  border: 0px solid #00cc66;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1px auto;
  padding: 1px;
  color: white;
  height: 180px;
  width: 95%;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    margin-bottom: 50px;
  }
  `;
  export const StyledLogos = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  `;
  export const StyledLogo = styled.div<LogoDivProps>`
    background-image: url(${props => props.logoUrl});
    background-size: cover;
    position: relative;
    width: 70px;
    height: 70px;
    border: 0px solid orange;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    overflow: hidden;
    z-index: 1;
  `;
  export const StyledButton = styled.button<StyledButtonProps>`
    position: relative;
    right: 0px;
    display: flex;
    background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
    color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
    width: 150px;
    min-width: 50px;
    height: 32px;
    justify-content: center;
    text-align: center;
    align-items: center;
    &:hover {
      background-color: #0cd9a0;
      border: 2px solid #fff;
    }
  `
  export const StyledNoteButton = styled.button<StyledButtonProps>`
    position: relative;
    display: flex;
    background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
    color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    z-index: 10;
    width: 100px;
    min-width: 50px;
    height: 24px;
    justify-content: center;
    text-align: center;
    align-items: center;
    &:hover {
      background-color: #0cd9a0;
      border: 2px solid #fff;
    }
  `
  export const Container1 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 0px solid red;
    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
  `;
  export const InputTitle = styled.textarea`
    width: 300px;
    padding: 5px;
    margin: 3px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: none;
    overflow: hidden;
    height: 25px;
    z-index: 1;
  `;
  export const InputOwner = styled.textarea`
    width: 200px;
    padding: 5px;
    margin: 3px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: none;
    overflow: hidden;
    height: 15px;
    z-index: 1;
  `;
  export const InputShortDescription = styled.textarea`
    display: flex;
    position: relative;
    width: 90%;
    min-width: 90%;
    max-width: 90%;
    height: 100px;
    min-height: 75px;
    padding: 5px;
    margin: 3px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    overflow-y: auto; /* Adds vertical scrollbar */
    resize: both; /* Allows resizing both width and height */
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
    `;
  export const LogoPreview = styled.div<{ logo: string }>`
    width: 150px;
    height: 150px;
    margin: 10px 0;
    background-color: #f0f0f0;
    background-image: url(${props => props.logo});
    background-size: cover;
    background-position: center;
    border: 3px solid #0ac18e;
    border-radius: 4px;
    overflow: visible;
    color: #333;
    @media (max-width: 1000px) {
      height: 100px;
      width: 100px;
    }
  `;
  export const BannerPreview1 = styled.div<{ banner: string }>`
    width: 500px;
    height: 120px;
    margin: 10px 0;
    margin-right: 20px;
    background-color: #f0f0f0;
    background-image: url(${props => props.banner});
    background-size: cover;
    background-position: center;
    border: 3px solid #0ac18e;
    border-radius: 4px;
    overflow: visible;
    color: #333;
    @media (max-width: 1000px) {
      height: 72px;
      width: 300px;
    }
  `;
  export const ErrorText1 = styled.div`
    color: red;
    margin-top: 10px;
    z-index: 1;
  `;
  export const ClearButton = styled.button`
    position: relative;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 0;
    font-weight: 700;
  `;
  export const StyledButtonSpinner = styled.div`
    position: relative;
    background-image: url(${Spinner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 45px;
    width: 45px;
    display: flex;
    border: 0px solid red;
  `;
  export const StyledUL = styled.ul`
    line-height: 24px;
  `;
  export const StyledBCHLogo = styled.div<StyledLogoProps>`
    background-image: url(${BCHLogo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    display: inline-block;
    vertical-align: bottom;
    border: 0px solid red;
    margin: 0 2px; // Add some horizontal spacing
  `;

//styling for date and time popup calendar
export const DatePickerStyles = createGlobalStyle`
  .react-datepicker {
    background-color: #222;
    border: 0px solid #0AC18E;
    z-index:10001;
  }
  .react-datepicker__day {
    color: #fff;
}
  .react-datepicker__day--selected {
    background-color: #0AC18E;
  }
  .react-datepicker__day:hover {
    color: #000;
  }
  .react-datepicker__header {
    background-color: #099971;
  }
  .react-datepicker__current-month {
    color: #fff;
  }
  .react-datepicker__day-name {
    color: #fff;
  }

  .react-datepicker-time__header {
    color: #fff;
  }
  .react-datepicker__time-box {
    background: #222;
  }
  .react-datepicker__time-list-item {
    color: #fff; 
  }
  .react-datepicker__time-list-item:hover {
    color: #000; 
  }
  .react-datepicker__time-list-item--selected {
    background-color: #099971 !important; /* Change to your preferred color for selected time */
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
`;