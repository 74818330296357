import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CasualLogo from '../../assets/img/casualLogo.png';

const Rules: React.FC = () => {

  return (
  <StyledRules>
    <StyledBackground />

    <StyledContainer>
      <StyledTextBackground />

      <StyledTextContainer>
        <StyledTitle>Our Rules</StyledTitle>

        <StyledSubText>
          FundMe.Cash is primarily focused on donation-based crowdfunding for the BitcoinCash ecosystem, but each campaign will be considered on a case-by-case basis.
          <p />
          These rules can be amended in the future. <a href="https://t.me/FundMe_Cash">Contact us</a> if you have any questions.
        </StyledSubText>

        <StyledText>FundMe does not vet campaigns.</StyledText>
        <StyledSubText>
          While FundMe will try to prevent obviously malicious campaigns from being listed, we cannot guarantee a campaign creator is being honest or has good intentions. Pledgers are always
          responsible for their own pledges and must do their own research into each campaign and campaign creator. 
        </StyledSubText>

        <StyledText>Creators must provide a way for pledgers to contact them.</StyledText>
        <StyledSubText>
          Pledgers may have questions before and after pledging, so creators must provide at least one way to be contacted in their campaign description, such as an email or social handle (e.g. Telegram, Discord, Twitter, etc.) 
        </StyledSubText>

        <StyledText>FundMe does not handle shipping or delivery of rewards.</StyledText>
        <StyledSubText>
          If your campaign is rewarding a physical or digital product to pledgers the campaign creator is responsible for all aspects of the reward, such as pledger information gathering, shipping, taxes, and any legal liabilities.
        </StyledSubText>

        <StyledText>FundMe does not handle taxes for campaigns.</StyledText>
        <StyledSubText>
          Campaign creators are responsible for any local tax requirements their campaign may be liable for, such as gift or income tax. The transfer of any raised funds is performed directly between the pledgers and the CashStarter contract, 
          and the campaign creator and the CashStarter contract.
        </StyledSubText>

        <StyledText>FundMe may permanently archive your campaign details.</StyledText>
        <StyledSubText>
          FundMe may take steps to ensure that campaign details remain available indefinitely, outside our control, in the interest of public accountability and to help prevent fraudulent campaigns. 
          This applies to both completed and canceled campaigns. These details could include the campaigns:
          <ul>
            <li>Title</li>
            <li>Creator</li>
            <li>Banner image</li>
            <li>Logo image</li>
            <li>Short Description</li>
            <li>Full Description</li>
            <li>Funding Goal</li>
            <li>Total Pledged Amount</li>
            <li>Updates</li>
          </ul>
        </StyledSubText>

        <StyledText>Respect others.</StyledText>
        <StyledSubText>
          Both campaign creators and pledgers must not engage in offensive, inappropriate, or disrespectful behaviour. Campaigns that do not follow this can be rejected or de-listed, and pledgers
          can have their messages removed.
        </StyledSubText>
        
        <StyledText>Pledgers are responsible for their pledge.</StyledText>
        <StyledSubText>
          Pledgers have full control over their pledge, such as refunding, until a campaign is claimed. After this point any issues that arise the pledger must deal with the campaign creator directly.<p />
          FundMe is not liable for pledger dissatisfaction regarding any pledges. Pledgers must always do their own research before donating to a campaign.
        </StyledSubText>

        <StyledText>Campaign creators must be honest and clearly presented.</StyledText>
        <StyledSubText>
          Campaign creators cannot mislead people or misrepresent facts. Creators should be candid about what they plan to accomplish, how they plan to do it, and fully outline any risks involved.
        </StyledSubText>

        <StyledText>Campaign creators are responsible for their campaigns promises.</StyledText>
        <StyledSubText>
          A campaign creator is making a social contract with its pledgers. FundMe is not responsible for a campaign creator that fails to deliver on their promises.
        </StyledSubText>

        <StyledText>Campaigns must be approved before they are listed.</StyledText>
        <StyledSubText>
          FundMe approves each campaign before it becomes visible on this website using the rules and criteria listed here. We reserve the right to remove any campaign currently listed if we determine it to be violating our rules,
          or if we are legally obligated to. <p /> Rejection or removal only prevents the campaign from being visible on the FundMe website; the actual campaign remains accessible on the 
          CashStarter smart contract and in control of the campaign creator.
          <p />
          The initial listing fee will not be refunded if your campaign is rejected or de-listed.
        </StyledSubText>

        <StyledText>Campaigns cannot support illegal activity.</StyledText>
        <StyledSubText>
          FundMe is hosted in Canada, so as a general rule campaigns that violate Canadian law will not be listed.<br />
          It is the campaign creator's responsibility to comply with their own local laws.
        </StyledSubText>

        <StyledText>Campaigns cannot offer equity.</StyledText>
        <StyledSubText>
          Investment is not permitted on FundMe. Campaigns cannot offer incentives like equity, revenue sharing, or investment opportunities as a part of their campaign. <br />
          Examples of these would be:
          <ul>
            <li>Providing a token that entitles the pledgers to a share of future profits as a part of the campaign</li>
            <li>Giving pledgers a voting share in your project as a part of the campaign</li>
          </ul>
        </StyledSubText>

        <StyledText>Campaigns cannot fundraise for charity.</StyledText>
        <StyledSubText>
          While nonprofits are welcome to launch campaigns on FundMe, campaigns cannot promise to raise funds to donate to a charity or cause. Funds raised on FundMe must go towards facilitating the project outlined by the creator.
        </StyledSubText>

        <StyledText>Campaigns cannot involve prohibited items.</StyledText>
        <StyledSubText>
          We will not list campaigns that are illegal, heavily regulated, or potentially dangerous for pledgers.
          <ul>
            <li>Any item claiming to diagnose, cure, treat, or prevent an illness or condition (whether via a device, app, book, nutritional supplement, or other means)</li>
            <li>Contests, gambling or raffles. Campaigns can raise funds to create these types of websites, apps, or events, but the campaign cannot <i>itself</i> be the contest/gamble/raffle.</li>
            <li>Energy food and drinks</li>
            <li>Offensive material (e.g., hate speech, discrimination, intolerance, encouraging violence against others, etc)</li>
            <li>Live animals</li>
            <li>Political-related fundraising</li>
            <li>Pornographic material</li>
            <li>Projects that simply repackage a previously-created product, without adding anything new to the final output. (e.g. dropshippers)</li>
            <li>Drugs (alchohol, nicotine, tobacco, marijuana, etc.)</li>
            <li>Weapons, replicas of weapons, and weapon accessories</li>
          </ul>
          If you are unsure if your campaign falls into this list then <a href="https://t.me/FundMe_Cash">contact us</a> 😊
        </StyledSubText>

        </StyledTextContainer>
      </StyledContainer>

  </StyledRules>
  );
};

interface LogoDivProps {
  logoUrl: string;
}

const StyledRules = styled.div`
  color: #fff; 
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #000;
  border: 0px solid red;
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100vh;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
`;
const StyledContainer = styled.div`
background-color: transparent;
border: 1px solid #00cc66;
border-top: 0px;
border-bottom: 0px;
display: flex;
flex-direction: column;
color: white;
min-height: 100vh;
height: 100%;
width: 50%;
position: relative;
text-align: center;
justify-content: top;
align-items: center;
@media (max-width: 1500px) {
  width: 80%;
}
`;
const StyledTextContainer = styled.div`
background-color: transparent;
display: flex;
flex-direction: column;
margin: 1px auto;
color: white;
height: 100%;
width: 95%;
position: relative;
text-align: left;
justify-content: top;
align-items: left;
padding-top: 5px;
padding-bottom: 20px;
padding-left: 20px;
padding-right: 20px;
border: 0px solid red;
`;
const StyledTextBackground = styled.div`
position: absolute;
width: 100%;
height: 100%;
min-height: 100vh;
background-color: #000;
opacity: 0.75;
z-index: 0;
`;
const StyledText = styled.h1`
text-decoration: none;
font-size: 18px;
color: #fff;
border-bottom: 1px solid #0AC18E;
z-index: 1;
margin-top: 30px;
`;
const StyledTitle = styled.h1`
text-decoration: none;
font-size: 32px;
color: #fff;
border-bottom: 1px solid #0AC18E;
z-index: 1;
align-items: center;
text-align: center;
`;
const StyledTextCenter = styled.div`
text-align: center;
`;
const StyledWarning = styled.div`
color: #ff0000;
font-size: 24px;
`;
const StyledSubText = styled.div`
position: relative;
font-size: 16px;
text-decoration: none;
color: #fff;
border: 0px solid pink;
justify-content: center;
align-items: center;
text-align: left;
padding-left: 10px;
font-weight: 500;
a {
  color: #0AC18E;
}
a:hover {
  color: #fff;
}
`;
const StyledLogos = styled.div`
display: flex;
flex-direction: row;
width: 100%;
position: relative;
text-align: center;
justify-content: center;
align-items: center;
gap: 10px;
`;
const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-size: cover;
  position: relative;
  width: 70px;
  height: 70px;
  border: 0px solid orange;
  border-radius: 25px 25px 25px 25px;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;

export default Rules;
