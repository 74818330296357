import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { Utxo } from 'cashscript';
//blockchain stuff
import { AddressCashStarter, MasterCategoryID } from '../../constants/values'
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { environmentUrl } from '../../constants/environment';
import { 
  sheenAnimation,
  fillAnimation,
  Container,
  StyledBackground,
  StyledBCH,
  StyledCards,
  DetailsButton,
  CampaignCard,
  StyledExpiredTitle,
  StyledCampaignID,
  StyledOwner,
  StyledAdBox,
  StyledDescription,
  StyledFooterRow,
  StyledRow,
  StyledRaisedRow,
  StyledCampaignButtonRow,
  CampaignTypeButton,
  StyledLogo,
  StyledName,
  StyledEnd,
  ProgressBar,
  ProgressBarText,
  ProgressBarSubText,
  Progress,
  StyledSpinner,
  StatsRow,
  StatBox,
  StatValue,
  StatLabel
} from './Campaigns.styles'

interface CampaignUtxo extends Utxo {
  name: string;
  owner: string;
  shortDescription: string;
  banner: string;
  endDate: string;
}
interface ArchivedCampaign {
  id: number;
  name: string;
  owner: string;
  shortDescription: string;
  banner: string;
  endDate: string;
}

const Campaigns: React.FC = () => {
  const navigate = useNavigate();
  const { electrumServer } = useBlockchainContext();
  const [campaigns, setCampaigns] = useState<Utxo[]>([]);
  const [expiredCampaigns, setExpiredCampaigns] = useState<Utxo[]>([]);
  const [campaignsMap, setCampaignsMap] = useState<Map<number, CampaignUtxo | null>>(new Map());
  const [expiredCampaignsMap, setExpiredCampaignsMap] = useState<Map<number, CampaignUtxo | null>>(new Map());
  const [archivedCampaignsMap, setArchivedCampaignsMap] = useState<Map<number, ArchivedCampaign | null>>(new Map());
  const [stringPledgeAmount, setStringPledgeAmount] = useState<string>("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignType, setCampaignType] = useState<string>('active');
  const [txPending, setTxPending] = useState(false);
  const [error, setError] = useState('');
  //fundme stats
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const [totalBCHRaised, setTotalBCHRaised] = useState<number>(0);
  const [totalPledges, setTotalPledges] = useState<number>(0);

  const hexToDecimal = (hex: string): number => {
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };

  //click Details to open campaign details page
  const handleDetailsClick = (id: number) => {
    navigate(`/campaign/${id}`);
  };

//////////////////////////////////////////////////
////////// UseEffect: Fetch stats on page load
//////////////////////////////////////////////////
  useEffect(() => {
    async function fetchStats() {
      try {
        // Fetch stats from server
        const statsResponse = await axios.get(environmentUrl + '/get-stats');
        const statsData = statsResponse.data;
        
        // Set initial stats
        setTotalCampaigns(statsData.totalCampaigns);
        setTotalBCHRaised(statsData.totalRaisedBCH);
        setTotalPledges(statsData.totalPledges);

      } catch (error) {
        console.error('Error fetching stats:', error);
        toast.info(`Error fetching stats:\n${error}`);
      }
    }
    fetchStats();
  }, []);

//////////////////////////////////////////////////
////////// UseEffect: Fetch campaigns on page load
//////////////////////////////////////////////////
  useEffect(() => {
    setIsLoading(true);  //starts loading spinner graphic
    
    async function getCampaigns() {
      if (!electrumServer) return;

      //delay to allow electrum to stabilize
      setTimeout(async () => {
        //get full list of campaigns hosted on FundMe server
        const fetchedCampaigns = await axios.get(environmentUrl + '/get-campaignlist');
        let campaignList = fetchedCampaigns.data;

        const cashStarterUTXOs: Utxo[] = await electrumServer.getUtxos(AddressCashStarter);
        //console.log(cashStarterUTXOs);
        console.log('[CashStarter] ' + cashStarterUTXOs.length + ' UTXOs');
        const filteredUTXOs = cashStarterUTXOs.filter( 
          utxo => utxo.token?.category == MasterCategoryID                  //only CashStarter NFT's
            && utxo.token?.nft?.capability == 'minting'                     //only minting ones
            && utxo.token.nft?.commitment.substring(70, 80) != 'ffffffffff' //not the masterNFT
        );
        console.log('[CashStarter] ' + filteredUTXOs.length + ' active campaigns');
        setCampaigns(filteredUTXOs);  //save active campaigns

        //if no utxos exist after filtering out the masterNFT
        if (filteredUTXOs.length == 0) {
          setIsLoading(false);
          setIsEmpty(true);
        }

        const expiredUTXOs = cashStarterUTXOs.filter(utxo => 
          utxo.token?.category === MasterCategoryID                       // only CashStarter NFT's
          && utxo.token?.nft?.capability === 'mutable'                    // expired ones
          && utxo.token.nft?.commitment.substring(70, 80) != 'ffffffffff' //not the masterNFT
        );
        console.log('[CashStarter] ' + expiredUTXOs.length + ' expired campaigns')
        setExpiredCampaigns(expiredUTXOs);  //save expired campaigns

        //Fill active map with active campaigns
        for (const utxo of filteredUTXOs) {                       //Iterate over filteredUTXOs to populate the map

          const campaignId = hexToDecimal(utxo.token?.nft?.commitment.substring(70,80) ?? "0");

          // Check if campaignId exists in the campaigns array
          const index = campaignList.indexOf(campaignId.toString());
          if (index !== -1) {
            // If found, remove it from the array
            campaignList.splice(index, 1);
          }

          const endBlock = hexToDecimal(utxo.token?.nft?.commitment.substring(52, 60) ?? "0");
          const endDate = await formatTime(endBlock);

          //fetch campaign metadata from server
          try {
            const response = await axios.get(environmentUrl + '/get-shortcampaign/' + campaignId);
            const campaignInfo = response.data;

            const newCampaign = {
              ...utxo,
              name: campaignInfo.name,
              owner: campaignInfo.owner,
              shortDescription: campaignInfo.shortDescription,
              banner: campaignInfo.banner,
              endDate: endDate
            };
          
            // Update the state with the new campaign
            setCampaignsMap((prevCampaignsMap) => {
              const updatedMap = new Map(prevCampaignsMap);
              updatedMap.set(campaignId, newCampaign);
              return updatedMap;
            });

          } catch (err) { //if server does not have the campaignId files hosted
            const error = err as AxiosError;
            if (error.response && error.response.status === 404) {
              console.log(`fetch error, campaign ${campaignId} not hosted on FundMe`);
            } else {
              console.log('fetch unknown error: ', err);
            }
          }
        };

        //Fill expired map with expired campaigns
        for (const utxo of expiredUTXOs) {                       //Iterate over expiredUTXOs to populate the map
          const campaignId = hexToDecimal(utxo.token?.nft?.commitment.substring(70,80) ?? "0");

            // Check if campaignId exists in the campaigns array
            const index = campaignList.indexOf(campaignId.toString());
            if (index !== -1) {
              // If found, remove it from the array
              campaignList.splice(index, 1);
            }
          const endBlock = hexToDecimal(utxo.token?.nft?.commitment.substring(52, 60) ?? "0");

          //fetch campaign metadata from server
          try {
            const response = await axios.get(environmentUrl + '/get-shortcampaign/' + campaignId);
            const campaignInfo = response.data;

            const expiredCampaign = {
              ...utxo,
              name: campaignInfo.name,
              owner: campaignInfo.owner,
              shortDescription: campaignInfo.shortDescription,
              banner: campaignInfo.banner,
              endDate: endBlock.toString()
            };
            
            // Update the state with the new campaign
            setExpiredCampaignsMap((prevExpiredCampaignsMap) => {
              const updatedMap = new Map(prevExpiredCampaignsMap);
              updatedMap.set(campaignId, expiredCampaign);
              return updatedMap;
            });

          } catch (err) { //if server does not have the campaignId files hosted
            const error = err as AxiosError;
            if (error.response && error.response.status === 404) {
              console.log(`fetch error, campaign ${campaignId} not hosted on FundMe`);
            } else {
              console.log('fetch unknown error: ', err);
            }
          }
        };

        //Fill archived map with archived campaigns
        for (const campaign of campaignList) {                       //Iterate over campaignList to populate the map
          //fetch campaign metadata from server
          try {
            const response = await axios.get(environmentUrl + '/get-shortcampaign/' + campaign);
            const campaignInfo = response.data;

            const archivedCampaign = {
              id: campaign,
              name: campaignInfo.name,
              owner: campaignInfo.owner,
              shortDescription: campaignInfo.shortDescription,
              banner: campaignInfo.banner,
              endDate: 'archived'
            };
            
            // Update the state with the new campaign
            setArchivedCampaignsMap((prevArchivedCampaignsMap) => {
              const updatedMap = new Map(prevArchivedCampaignsMap);
              updatedMap.set(campaign, archivedCampaign);
              return updatedMap;
            });

          } catch (err) { //if server does not have the campaignId files hosted
            const error = err as AxiosError;
            if (error.response && error.response.status === 404) {
              console.log(`fetch error, campaign ${campaign} not hosted on FundMe`);
            } else {
              console.log('fetch unknown error: ', err);
            }
          }
        };

        setIsLoading(false);
    }, 2000); // Delay of 2 seconds);
  }
    getCampaigns();

  }, [electrumServer]);

  async function formatTime(blocks: number): Promise<string> {
    const blockHeight = await electrumServer.getBlockHeight();
    const blocksRemaining = Math.max(blocks - blockHeight, 0);

    if (blocksRemaining == 0) {
      return 'Expired';
    }

    const totalMinutes = blocksRemaining * 10;
    const totalHours = totalMinutes / 60;
    const remainingHours = totalHours % 24;
    const days = Math.floor(totalHours / 24);
    const hours = Math.floor(totalHours % 24);
    const minutes = Math.floor((remainingHours - hours) * 60);

    let endDate = '';
    if (days > 0) {
      endDate += `${days}d `;
    }
    if (hours > 0 || days > 0) { // Include hours if there are any days
      endDate += `${hours}h `;
    }
    endDate += `${minutes}m`;

    return endDate;
  }

  return (
    <Container>
      <StyledBackground />
      
      <StatsRow>
        <StatBox>
          <StatLabel>Past Campaigns</StatLabel>
          <StatValue>{totalCampaigns}</StatValue>
        </StatBox>
        <StatBox>
          <StatLabel>Active Campaigns</StatLabel>
          <StatValue>{campaigns.length}</StatValue>
        </StatBox>
        <StatBox>
          <StatLabel>Total Raised</StatLabel>
          <StyledRaisedRow>
            <StyledBCH />
            <StatValue>{totalBCHRaised.toFixed(2)}</StatValue>
          </StyledRaisedRow>
        </StatBox>
        <StatBox>
          <StatLabel>Total Pledges</StatLabel>
          <StatValue>{totalPledges}</StatValue>
        </StatBox>
      </StatsRow>

      <StyledCampaignButtonRow>
        <CampaignTypeButton onClick={() => setCampaignType('active')} $isActive={campaignType == 'active'}>Active</CampaignTypeButton> 
        <CampaignTypeButton onClick={() => setCampaignType('stopped')} $isActive={campaignType == 'stopped'}>Stopped</CampaignTypeButton> 
        <CampaignTypeButton onClick={() => setCampaignType('archived')} $isActive={campaignType == 'archived'}>Archived</CampaignTypeButton> 
      </StyledCampaignButtonRow>

      {isEmpty && campaignType == 'active' &&
        <StyledExpiredTitle>No campaigns currently active.</StyledExpiredTitle>
      }

      {campaignType == 'active' &&
        <StyledCards>
          {[...campaignsMap.values()].map((campaign, index) => (
            campaign && (
            <CampaignCard key={campaign.txid}>
              <StyledLogo logourl={campaign.banner}/>
              <DetailsButton disabled={campaign.shortDescription == 'Campaign pending listing approval'} onClick={() => handleDetailsClick(hexToDecimal(campaign.token?.nft?.commitment.substring(70,80) ?? "0"))}>Details</DetailsButton>
              <StyledAdBox>
                <ProgressBar>
                  <Progress progress={(Number(campaign.satoshis) / hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100} />
                  <ProgressBarText>{((Number(campaign.satoshis) / hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100).toFixed(2)}%</ProgressBarText>
                  <ProgressBarSubText>{(Number(campaign.satoshis) / 100000000 )} / {(hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000)}</ProgressBarSubText>
                </ProgressBar>

                <StyledRow>
                  <StyledName>{campaign.name}</StyledName>
                  <StyledEnd>Ends:<span style={{fontWeight: '500'}}>{campaign.endDate}</span></StyledEnd>
                </StyledRow>

                <StyledDescription>
                  {campaign.shortDescription}
                </StyledDescription>

              <StyledFooterRow>
                <StyledCampaignID>Campaign #{hexToDecimal(campaign.token?.nft?.commitment.substring(70,80) ?? "0")}</StyledCampaignID>
                <StyledOwner>By: {campaign.owner}</StyledOwner>
              </StyledFooterRow>

              </StyledAdBox>
            </CampaignCard>
          )))}
          {isLoading == true &&
            <StyledSpinner />
          }
        </StyledCards>
      }

      {campaignType == 'stopped' && expiredCampaignsMap.size > 0 &&
        <StyledExpiredTitle>Refunds can be done in Details.</StyledExpiredTitle>
      }
      {campaignType == 'stopped' && expiredCampaignsMap.size == 0 && !isLoading &&
        <StyledExpiredTitle>No campaigns currently stopped.</StyledExpiredTitle>
      }
      {campaignType == 'stopped' &&
        <StyledCards>
            {[...expiredCampaignsMap.values()].map((campaign, index) => (
              campaign && (
                <CampaignCard key={campaign.txid}>
                  <StyledLogo logourl={campaign.banner}/>
                  <DetailsButton onClick={() => handleDetailsClick(hexToDecimal(campaign.token?.nft?.commitment.substring(70,80) ?? "0"))}>Details</DetailsButton>
                  <StyledAdBox>
                    <ProgressBar>
                      <Progress progress={(Number(campaign.satoshis) / hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100} />
                      <ProgressBarText>{((Number(campaign.satoshis) / hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100).toFixed(2)}%</ProgressBarText>
                      <ProgressBarSubText>{(Number(campaign.satoshis) / 100000000 )} / {(hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000)}</ProgressBarSubText>
                    </ProgressBar>

                    <StyledRow>
                      <StyledName>Campaign #{hexToDecimal(campaign.token?.nft?.commitment.substring(70,80) ?? "0")}</StyledName>
                      <StyledEnd>End Block:{campaign.endDate}</StyledEnd>
                    </StyledRow>

                    <StyledDescription>
                      {campaign.shortDescription}
                    </StyledDescription>

                    <StyledFooterRow>
                      <StyledCampaignID>{campaign.name}</StyledCampaignID>
                      <StyledOwner>By: {campaign.owner}</StyledOwner>
                    </StyledFooterRow>

                  </StyledAdBox>
                </CampaignCard>
              )))}
              {isLoading == true &&
                <StyledSpinner />
              }
        </StyledCards>
      }

      {campaignType == 'archived' &&

        <StyledCards>
          {[...archivedCampaignsMap.values()].map((campaign, index) => (
            campaign && (
              <CampaignCard key={campaign.id}>
                <StyledLogo logourl={campaign.banner}/>
                <DetailsButton onClick={() => handleDetailsClick(campaign.id)}>Details</DetailsButton>
                <StyledAdBox>

                  <StyledRow>
                    <StyledName>Campaign #{campaign.id}</StyledName>
                  </StyledRow>

                  <StyledDescription>
                    {campaign.shortDescription}
                  </StyledDescription>

                  <StyledFooterRow>
                    <StyledCampaignID>{campaign.name}</StyledCampaignID>
                    <StyledOwner>By: {campaign.owner}</StyledOwner>
                  </StyledFooterRow>

                </StyledAdBox>
              </CampaignCard>
            )))
          }

            {isLoading == true &&
              <StyledSpinner />
            }
        </StyledCards>
      }

    </Container>
  );
};

export default Campaigns;
